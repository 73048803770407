﻿var iOSBridge = iOSBridge || {};
iOSBridge.basicTimeout = 5000;

iOSBridge.getMessageHandlerName = function (area) {
    return 'NativeBridge_' + area;
};

iOSBridge.send = function (methodName, area, data) {
    var handlerName = iOSBridge.getMessageHandlerName(area);
    var message = { method: methodName, data: data };
    webkit.messageHandlers[handlerName].postMessage(JSON.stringify(message));
};

iOSBridge.call = function (methodName, area, callback, data) {
    if (NativeBridgeCallback.hasMethod(methodName, area)) {
        iOSBridge.send(methodName, area, data);
        callback({ status: 1, data: null });
        return;
    }
    callback({ status: 0, data: null });
    return;
};

iOSBridge.callWithCallback = function (methodName, area, callback, data, timeout) {
    if (NativeBridgeCallback.hasMethod(methodName, area)) {
        if (timeout === undefined || timeout === null) {
            timeout = iOSBridge.basicTimeout;
        }

        var t;
        var eventListenerName = 'native.' + area + '.' + methodName;
        var id = NativeReactor.addOnceEventListener(eventListenerName, function (d) {
            clearTimeout(t);
            callback({ status: 1, data: d });
        });

        t = setTimeout(function () {
            NativeReactor.removeEventListener(eventListenerName, id);
            callback({ status: 0, data: null });
        }, timeout);

        iOSBridge.send(methodName, area, data);
        return;
    }

    callback({ status: 0, data: null });
    return;
};

/* Basic
------------------------------------ */
iOSBridge.basic = {
    getAvailableMethods: function (callback) {
        var t;
        var eventListenerName = 'native.basic.getAvailableMethods';
        var id = NativeReactor.addOnceEventListener(eventListenerName, function (d) {
            clearTimeout(t);
            callback({ status: 1, data: d });
        });
    
        t = setTimeout(function () {
            NativeReactor.removeEventListener(eventListenerName, id);
            callback({ status: 0, data: null });
        }, iOSBridge.basicTimeout);
    
        var handlerName = iOSBridge.getMessageHandlerName('basic');
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers[handlerName]) {
            iOSBridge.send('getAvailableMethods', 'basic');
        } else {
            // We did not have the messageHandler so we fail this
            callback({ status: 0, data: null });
        }
        return;
    },
    appIsReady: function (callback, readyObject) {
        iOSBridge.call('appIsReady', 'basic', callback, JSON.stringify(readyObject));
    },
    readyForWebClientConfiguration: function (callback) {
        iOSBridge.call('readyForWebClientConfiguration', 'basic', callback);
    },
    getAppInfo: function (callback) {
        iOSBridge.callWithCallback('getAppInfo', 'basic', callback);
    },
    reload: function (callback) {
        iOSBridge.call('reload', 'basic', callback);
    }
};

/* User
------------------------------------ */
iOSBridge.user = {
    isLoggedIn: function (callback, allowPush) {
        iOSBridge.call('isLoggedIn', 'user', callback, allowPush);
    },
    isLoggedOut: function (callback) {
        iOSBridge.call('isLoggedOut', 'user', callback);
    },
    getLoginStatus: function (callback) {
        iOSBridge.callWithCallback('getLoginStatus', 'user', callback);
    }
};

/* Biometric
------------------------------------ */
iOSBridge.biometric = {
    getStatus: function(callback, requestData){
        iOSBridge.callWithCallback('getStatus', 'biometric', callback, JSON.stringify(requestData));
    },
    getPin: function(callback, requestData){
        var timeout = 1000 * 60 * 5; // 5 minutes
        iOSBridge.callWithCallback('getPin', 'biometric', callback, JSON.stringify(requestData), timeout);
    },
    savePin: function(callback, requestData){
        var timeout = 1000 * 60 * 5; // 5 minutes
        iOSBridge.callWithCallback('savePin', 'biometric', callback, JSON.stringify(requestData), timeout);
    },
    clearPin: function(callback, requestData){
        var timeout = 1000 * 60; // 1 minute
        iOSBridge.callWithCallback('clearPin', 'biometric', callback, JSON.stringify(requestData), timeout);
    }
};

/* Push
------------------------------------ */
iOSBridge.push = {
    togglePush: function (callback, enabled) {
        iOSBridge.call('togglePush', 'push', callback, enabled);
    },
    getToken: function (callback) {
        iOSBridge.callWithCallback('getToken', 'push', callback);
    },
    setBadge: function (callback, data) {
        iOSBridge.call('setBadge', 'push', callback, data);
    },
    sendLocalPush: function (callback, data) {
        iOSBridge.call('sendLocalPush', 'push', callback, JSON.stringify(data));
    }
};

/* Visual
------------------------------------ */
iOSBridge.visual = {
    changeStatusBar: function (callback, data) {
        iOSBridge.call('changeStatusBar', 'visual', callback, JSON.stringify(data));
    }
};

/* Barcode
------------------------------------ */
iOSBridge.scanner = {
    scanBarcode: function (callback, formats) {
        var timeout = 1000 * 60 * 10; // Wait up to 10 minutes
        iOSBridge.callWithCallback('scanBarcode', 'scanner', callback, JSON.stringify(formats), timeout);
    }
};

/* Sharing
------------------------------------ */
iOSBridge.sharing = {
    shareOnFacebook: function (callback, data) {
        var timeout = 1000 * 60 * 10; // Wait up to 10 minutes
        iOSBridge.callWithCallback('shareOnFacebook', 'sharing', callback, JSON.stringify(data), timeout);
    }
};

window.iOSBridge = iOSBridge;