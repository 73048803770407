﻿(function () {
    'use strict';

    angular.module('App.Common')
        .factory('RichMentionService', [() => {
            return {
                parseMentionsFromMultipleTexts: parseMentionsFromMultipleTexts,
                parseMentionsFromText: parseMentionsFromText,
                parseHashTagsFromText: parseHashTagsFromText,
                parseHashTagsFromMultipleTexts: parseHashTagsFromMultipleTexts,
                parseNewHashtags: parseNewHashtags,
                parseOldMentions: parseOldMentions,
                parseOldImages: parseOldImages
            };

            function parseMentionsFromMultipleTexts(texts) {
                const mentions = {userMentions: [], userGroupMentions: [], departmentMentions: []};

                texts.forEach(text => {
                    if (text && text.length) {
                        const {userMentions, userGroupMentions, departmentMentions} = parseMentionsFromText(text);

                        mentions.userMentions = [...mentions.userMentions, ...userMentions];
                        mentions.userGroupMentions = [...mentions.userGroupMentions, ...userGroupMentions];
                        mentions.departmentMentions = [...mentions.departmentMentions, ...departmentMentions];
                    }
                })

                return mentions
            }

            function parseMentionsFromText(text) {
                const mentions = {userMentions: [], userGroupMentions: [], departmentMentions: []};

                if (text) {
                    mentions.userMentions = getMentions(text, 'user-mention');
                    mentions.userGroupMentions = getMentions(text, 'user-group-mention');
                    mentions.departmentMentions = getMentions(text, 'department-mention');
                }

                return mentions
            }

            function getMentions(text, target) {
                const string = `<${target}.*? token="(\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12})".*?>@.*?<\\/${target}>`,
                    regExp = new RegExp(string, 'gm')

                return [...text.matchAll(regExp)].map(arr => arr[1])
            }

            function parseHashTagsFromMultipleTexts(texts) {
                return texts.map(text => {
                    return text ? parseHashTagsFromText(text) : []
                }).flat();
            }

            function parseHashTagsFromText(text) {
                const pattern = /#[^\s!@#$%^&*()=+.\/,\[{\]};:'"?><]+/ig;

                if (text && text.length) {
                    const match = text.match(pattern);

                    if (match) {
                        return match.map(tag => tag.replace('#', ''));
                    }
                }
                return []
            }

            function parseNewHashtags(text) {
                const el = $('<div></div>').html(text);
                el.find("hash-tag").contents().unwrap()
                return el.html().replace(/(#[^\s!@#$%^&*()=+.\/,\[{\]};:'"?><]+)/ig, `<hash-tag translate="no">$&</hash-tag>`);
            }

            function parseOldMentions(text, {taggedDepartments, taggedUserGroups, taggedUsers}) {
                const tokens = text.match(/@(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})/gm);
                let user, department, userGroup;

                if (tokens && tokens.length) {
                    tokens.forEach(item => {
                        const token = item.replace('@', '');

                        if (taggedUsers && taggedUsers.length) {
                            user = taggedUsers.find(element => element.UserToken === token)
                        }
                        if (taggedDepartments && taggedDepartments.length) {
                            department = taggedDepartments.find(element => element.DepartmentToken === token)
                        }
                        if (taggedUserGroups && taggedUserGroups.length) {
                            userGroup = taggedUserGroups.find(element => element.UserGroupToken === token)
                        }

                        if (user) {
                            text = text.replace('@' + token,
                                `<user-mention token="${token}">@${user.UserName}</user-mention>`);
                        }
                        if (department) {
                            text = text.replace('@' + token,
                                `<department-mention token="${token}">@${department.DepartmentName}</department-mention>`);
                        }
                        if (userGroup) {
                            text = text.replace('@' + token,
                                `<user-group-mention token="${token}">@${userGroup.UserGroupName}</user-group-mention>`);
                        }
                    });
                }
                return text;
            }

            function parseOldImages(text, embeddedMedias, callback) {
                const regexString = '<img[^>]*? src="([^"]*)".*?>',
                    regExp = new RegExp(regexString, 'gmi');

                var foundMedia = [];

                Array.from(text.matchAll(regExp)).forEach(arr => {
                    var mediaIndex = embeddedMedias.findIndex(o => o.UrlEncoded == arr[1]);
                    if (mediaIndex != typeof undefined && mediaIndex >= 0) {
                        text = text.replace(arr[0], `<p class="p-top"><media item="embeddedMedias[${mediaIndex}]" on-media-loaded="onMediaLoaded" enable-popup="true"></media></p>`);

                        if (foundMedia.indexOf(embeddedMedias[mediaIndex]) === -1) {
                            foundMedia.push(embeddedMedias[mediaIndex]);
                        }
                    }
                })

                if (callback) {
                    callback(foundMedia);
                }

                return text;
            }

        }]);
})();
