﻿(function () {
    'use strict';
    var app = angular.module('App.Elements');

    app.directive('loading', ['BasicHelper',
        function (BasicHelper) {
            return {
                restrict: 'E',
                replace: true,
                template: require('./Loading.directive.html'),
                link: function (scope) {

                }
            }
        }
    ]);
})();