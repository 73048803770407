﻿(() => {
    'use strict';
    
    angular.module('App.Common')
        .component('lazyInitItem', {
            template: require('./LazyInitWrapperComponent.html'),
            controllerAs: 'ctrl',
            transclude: true,
            bindings: {
                placeholderRows: '<'
            },
            controller: ['$scope', '$element', 'LazyInitWrapperService', function ($scope, $element, LazyInitWrapperService) {
                const ctrl = this;

                ctrl.$onInit = init;
                ctrl.$onDestroy = destroy;

                function init() {
                    LazyInitWrapperService.observe($element[0], () => {
                        $scope.$apply(() => {
                            ctrl.show = true;
                        })
                    });
                }
                
                function destroy() {
                    LazyInitWrapperService.unobserve($element[0]);   
                }
            }]
        });
})();
