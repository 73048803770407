﻿(function () {
    'use strict';
    var app = angular.module('App.Basics');

    app.factory('NativeFactory', ['$rootScope', 'events', '$http', '$compile', '$animate', '$document', function ($rootScope, events, $http, $compile, $animate, $document) {
        var isInitialized = false;
        var lastVersionCheck = null;
        const versionCheckIntervalTime = 1000 * 60 * 10; // 5 minutes;

        var f = {
            init: function (callback) {
                if (isInitialized === false) {
                    // Initialized native helper
                    NativeHelper.init(function () {
                        // Done, we don't act different if it failed
                        initFactory();
                        if (callback) {
                            callback();
                        }
                    });
                } else if (callback) {
                    // It is already initialized, so you can now run the callback
                    callback();
                }
            },
            checkVersion: function () {
                if (lastVersionCheck != null && (new Date() - lastVersionCheck) < versionCheckIntervalTime) {
                    return;
                }

                lastVersionCheck = new Date();

                $http.get('/NativeDownload/CheckNativeVersionAsync').then((response) => {
                    // Check if we got a download URL, because then we need to download the new version
                    if (response.status === 200 && response.data) {
                        // We got some data to look through
                        if (response.data.DownloadUrl.length) {
                            var $scope = $rootScope.$new(true);
                            var $element = $compile('<native-upgrade download-url="' + response.data.DownloadUrl + '"></native-upgrade>')($scope);
                            $animate.enter($element, $document[0].body, $document[0].body.lastChild);
                        }
                    }
                }, function () {
                    // We don't handle errors
                });
            },
            hasMethod: function (method) {
                return NativeHelper.hasMethod(method);
            },
            basic: {
                appIsReady: function (readyObject) {
                    if (readyObject === undefined || readyObject === null) {
                        // Create readyobject
                        var colors = getDefaultColors();
                        if (colors && colors !== null) {
                            readyObject = {
                                statusBar: {
                                    text: colors.statusbarTextColor,
                                    background: colors.statusbarBackgroundColor
                                }
                            };
                        }
                    }

                    NativeHelper.methods.basic.appIsReady(null, readyObject);
                },
                readyForWebClientConfiguration: function() {
                    readyForWebClientConfiguration();
                },
                getAppInfo: function (callback) {
                    NativeHelper.methods.basic.getAppInfo(callback);
                },
                getAvailableMethods: function (callback) {
                    NativeHelper.methods.basic.getAvailableMethods(callback);
                },
                reload: function (callback) {
                    NativeHelper.methods.basic.reload(callback);
                }
            },
            user: {
                isLoggedIn: function (allowPush) {
                    NativeHelper.methods.user.isLoggedIn(null, allowPush);
                },
                isLoggedOut: function () {
                    NativeHelper.methods.user.isLoggedOut();
                },
                getLoginStatus: function (callback) {
                    NativeHelper.methods.user.getLoginStatus(callback);
                }
            },
            biometric: {
                getStatus: function(callback, userToken) {
                    NativeHelper.methods.biometric.getStatus(callback, userToken);
                },
                getPin: function(callback, userToken) {
                    NativeHelper.methods.biometric.getPin(callback, userToken);
                },
                savePin: function(callback, userToken, pinCode) {
                    NativeHelper.methods.biometric.savePin(callback, userToken, pinCode);
                },
                clearPin: function(callback, userToken) {
                    NativeHelper.methods.biometric.clearPin(callback, userToken);
                }
            },
            push: {
                togglePush: function (enabled) {
                    NativeHelper.methods.push.togglePush(null, enabled);
                },
                getToken: function (callback) {
                    NativeHelper.methods.push.getToken(callback);
                },
                setBadge: function (badge) {
                    NativeHelper.methods.push.setBadge(null, badge);
                },
                sendLocalPush: function (title, body, badge, payload) {
                    NativeHelper.methods.push.sendLocalPush(null, title, body, badge, payload);
                }
            },
            visual: {
                changeStatusBar: function (backgroundColor, textColor) {
                    NativeHelper.methods.visual.changeStatusBar(null, backgroundColor, textColor);
                },
                useDefaultStatusBar: function () {
                    // Tell the app about colors
                    var colors = getDefaultColors();
                    if (colors && colors !== null) {
                        NativeHelper.methods.visual.changeStatusBar(null, colors.statusbarBackgroundColor, colors.statusbarTextColor);
                    }
                }
            },
            scanner: {
                scanBarcode: function (callback, formats) {
                    NativeHelper.methods.scanner.scanBarcode(callback, formats);
                }
            },
            sharing: {
                shareOnFacebook: function (callback, url, quote) {
                    NativeHelper.methods.sharing.shareOnFacebook(callback, url, quote);
                }
            }
        };

        function initFactory() {
            if (isInitialized === true)
                return;

            isInitialized = true;
            // Tell the app that we've connected
            readyForWebClientConfiguration();

            // Start listening for Native Events
            NativeReactor.addEventListener('native.push.newPushToken', function (data) {
                // Broadcast push token
                if (data) {
                    if (_.isString(data.providerIdentifier) && data.providerIdentifier.length) {
                        $rootScope.$broadcast(events.PUSH_NEWTOKEN, data);
                    } else {
                        // backward compatibility
                        $rootScope.$broadcast(events.PUSH_NEWTOKEN, {
                            newPushToken: data.token,
                            firebaseProjectReference: data.firebaseProjectId
                        });
                    }
                }
            });

            NativeReactor.addEventListener('native.push.messageRecieved', (data) => {
                // Broadcast push message
                $rootScope.$broadcast(events.PUSH_MESSAGE, {
                    pushMessage: data
                });
            });

            NativeReactor.addEventListener('native.basic.onAppPause', () => {
                var model = {
                    isNativeEvent: true
                };
                // App went into sleep mode
                $rootScope.$broadcast(events.APP_HIDE, model);
            });

            NativeReactor.addEventListener('native.basic.onAppResume', (data) => {
                var model = {
                    deviceId: null,
                    isNativeEvent: true
                };

                // Check if we get an actual object in return
                if (_.isObject(data) === true && _.isString(data) === false) {
                    _.merge(model, data);
                }

                // App resume after being in the background
                $rootScope.$broadcast(events.APP_UNHIDE, model);
                // At this step we always check the version
                if (NativeHelper.isWrapperApp() === true) {
                    f.checkVersion();
                }
            });

            // Keyboard events
            NativeReactor.addEventListener('native.keyboard.onWillHide', () => {
                $rootScope.$broadcast(events.KEYBOARD_WILLHIDE);
            });
            NativeReactor.addEventListener('native.keyboard.onDidHide', () => {
                $rootScope.$broadcast(events.KEYBOARD_DIDHIDE);
            });
            NativeReactor.addEventListener('native.keyboard.onWillShow', () => {
                $rootScope.$broadcast(events.KEYBOARD_WILLSHOW);
            });
            NativeReactor.addEventListener('native.keyboard.onDidShow', () => {
                $rootScope.$broadcast(events.KEYBOARD_DIDSHOW);
            });
        }

        function readyForWebClientConfiguration(){
            NativeHelper.methods.basic.readyForWebClientConfiguration();
        }

        function getDefaultColors() {
            try {
                // Get colors
                var statusbarBackgroundColor = getComputedStyle(document.body).getPropertyValue('--native-statusbar-bg-color');
                var statusbarTextColor = getComputedStyle(document.body).getPropertyValue('--native-statusbar-text-color');

                return {
                    statusbarBackgroundColor: statusbarBackgroundColor,
                    statusbarTextColor: statusbarTextColor
                };
            } catch (ex) {
                // Do nothing
                console.error(ex);
            }
            return null;
        }

        // Init NativeFactory
        f.init();
        return f;
    }]);
})();