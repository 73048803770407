﻿var AndroidBridge = AndroidBridge || {};
AndroidBridge.basicTimeout = 5000;

AndroidBridge.awaitCallback = function (methodName, area, callback, timeout) {
    if (NativeBridgeCallback.hasMethod(methodName, area)) {
        if (timeout === undefined || timeout === null) {
            timeout = AndroidBridge.basicTimeout;
        }

        var t;
        var eventListenerName = 'native.' + area + '.' + methodName;
        var id = NativeReactor.addOnceEventListener(eventListenerName, function (d) {
            clearTimeout(t);
            callback({ status: 1, data: d });
        });

        t = setTimeout(function () {
            NativeReactor.removeEventListener(eventListenerName, id);
            callback({ status: 0, data: null });
        }, timeout);

    } else {
        callback({ status: 0, data: null });
    }
};


/* Basic
------------------------------------ */
AndroidBridge.basic = {
    getAvailableMethods: function (callback) {
        var data = NativeBridge.basic_getAvailableMethods();
        data = NativeBridgeCallback.parseJSON(data);
        NativeBridgeCallback.Methods = data;
        callback({ status: 1, data: data });
        return;
    },
    appIsReady: function (callback, readyObject) {
        if (NativeBridgeCallback.hasMethod('appIsReady', 'basic', true)) {
            NativeBridge.basic_appIsReady(JSON.stringify(readyObject));
            callback({ status: 1, data: null });
            return;
        } else if (NativeBridgeCallback.hasMethod('appIsReady', 'basic')) {
            NativeBridge.basic_appIsReady();
            callback({ status: 1, data: null });
            return;
        }
        callback({ status: 0, data: null });
        return;
    },
    readyForWebClientConfiguration: function (callback) {
        if (NativeBridgeCallback.hasMethod('readyForWebClientConfiguration', 'basic')) {
            NativeBridge.basic_readyForWebClientConfiguration();
            callback({ status: 1, data: null });
            return;
        }
        callback({ status: 0, data: null });
        return;
    },
    getAppInfo: function (callback) {
    
        if (NativeBridgeCallback.hasMethod('getAppInfo', 'basic')) {
            var data = NativeBridge.basic_getAppInfo();
            data = NativeBridgeCallback.parseJSON(data);
            callback({ status: 1, data: data });
            return;
        }
    
        callback({ status: 0, data: null });
        return;
    },
    reload: function (callback) {
        if (NativeBridgeCallback.hasMethod('reload', 'basic')) {
            NativeBridge.basic_reload();
            callback({ status: 1, data: null });
            return;
        }
        callback({ status: 0, data: null });
        return;
    }
};

/* User
------------------------------------ */
AndroidBridge.user = {
    isLoggedIn: function (callback, allowPush) {
        if (NativeBridgeCallback.hasMethod('isLoggedIn', 'user')) {
            NativeBridge.user_isLoggedIn(allowPush);
            callback({ status: 1, data: null });
            return;
        } 
        callback({ status: 0, data: null });
        return;
    },
    isLoggedOut: function (callback) {
        if (NativeBridgeCallback.hasMethod('isLoggedOut', 'user')) {
            NativeBridge.user_isLoggedOut();
            callback({ status: 1, data: null });
            return;
        }
        callback({ status: 0, data: null });
        return;
    },
    getLoginStatus: function (callback) {
        if (NativeBridgeCallback.hasMethod('getLoginStatus', 'user')) {
            var data = NativeBridge.user_getLoginStatus();
            data = NativeBridgeCallback.parseBoolean(data);
            callback({ status: 1, data: data });
            return;
        }
        callback({ status: 0, data: null });
        return;
    }
};

/* Biometric
------------------------------------ */
AndroidBridge.biometric = {
    getStatus: function(callback, requestData) {
        if(NativeBridgeCallback.hasMethod('getStatus', 'biometric')) {
            var result = NativeBridge.biometric_getStatus(JSON.stringify(requestData));
            callback({ status: 1, data: NativeBridgeCallback.parseJSON(result) });
            return;
        }
        callback({ status: 0, data: null });
        return;
    },
    getPin: function(callback, requestData) {
        if(NativeBridgeCallback.hasMethod('getPin', 'biometric')) {
            var result = NativeBridge.biometric_getPin(JSON.stringify(requestData));
            callback({ status: 1, data: NativeBridgeCallback.parseJSON(result) });
            return;
        }
        callback({ status: 0, data: null });
        return;
    },
    savePin: function(callback, requestData) {
        if(NativeBridgeCallback.hasMethod('savePin', 'biometric')) {
            var result = NativeBridge.biometric_savePin(JSON.stringify(requestData));
            callback({ status: 1, data: NativeBridgeCallback.parseJSON(result) });
            return;
        }
        callback({ status: 0, data: null });
        return;
    },
    clearPin: function(callback, requestData) {
        if(NativeBridgeCallback.hasMethod('clearPin', 'biometric')) {
            var result = NativeBridge.biometric_clearPin(JSON.stringify(requestData));
            callback({ status: 1, data: NativeBridgeCallback.parseJSON(result) });
            return;
        }
        callback({ status: 0, data: null });
        return;
    }
};

/* Push
------------------------------------ */
AndroidBridge.push = {
    togglePush: function (callback, enabled) {    
        if (NativeBridgeCallback.hasMethod('togglePush', 'push')) {
            NativeBridge.push_togglePush(enabled);
            callback({ status: 1, data: null });
            return;
        }
        callback({ status: 0, data: null });
        return;
    },
    getToken: function (callback) {
        if (NativeBridgeCallback.hasMethod('getToken', 'push')) {
            var data = NativeBridge.push_getToken();
    
            var jsonParsed = NativeBridgeCallback.parseJSON(data);
            if (_.isString(jsonParsed)) {
                // Convert to new model if data is still a string result
                jsonParsed = {
                    token: jsonParsed,
                    firebaseProjectId: null
                };
            }
    
            callback({ status: 1, data: jsonParsed });
            return;
        }
        callback({ status: 0, data: null });
        return;
    },
    setBadge: function (callback, badge) {
        if (NativeBridgeCallback.hasMethod('setBadge', 'push')) {
            NativeBridge.push_setBadge(badge);
            callback({ status: 1, data: null });
            return;
        }
    
        callback({ status: 0, data: null });
        return;
    },
    sendLocalPush: function (callback, pushData) {
        if (NativeBridgeCallback.hasMethod('sendLocalPush', 'push')) {
            NativeBridge.push_sendLocalPush(JSON.stringify(pushData));
            callback({ status: 1, data: null });
            return;
        }
        callback({ status: 0, data: null });
        return;
    }
};

/* Visual
------------------------------------ */
AndroidBridge.visual = {
    changeStatusBar: function (callback, data) {
        if (NativeBridgeCallback.hasMethod('changeStatusBar', 'visual')) {
            NativeBridge.visual_changeStatusBar(JSON.stringify(data));
            callback({ status: 1, data: null });
            return;
        }
        callback({ status: 0, data: null });
        return;
    }
};

/* Scanner
------------------------------------ */
AndroidBridge.scanner = {
    scanBarcode: function (callback, formats) {
        if (NativeBridgeCallback.hasMethod('scanBarcode', 'scanner')) {
            var timeout = 1000 * 60 * 10; // Wait up to 10 minutes
            AndroidBridge.awaitCallback('scanBarcode', 'scanner', function (data) {
                callback(data);
            }, timeout);
            NativeBridge.scanner_scanBarcode(JSON.stringify(formats));
            return;
        }
    
        callback({ status: 0, data: null });
        return;
    }
};

/* Sharing
------------------------------------ */
AndroidBridge.sharing = {
    shareOnFacebook: function (callback, data) {
        if (NativeBridgeCallback.hasMethod('shareOnFacebook', 'sharing')) {
            var timeout = 1000 * 60 * 10; // Wait up to 10 minutes
            AndroidBridge.awaitCallback('shareOnFacebook', 'sharing', function (data) {
                callback(data);
            }, timeout);
            NativeBridge.sharing_shareOnFacebook(JSON.stringify(data));
            return;
        }
    
        callback({ status: 0, data: null });
        return;
    }
};

window.AndroidBridge = AndroidBridge;