﻿(function () {
    'use strict';
    var app = angular.module('App.Filters');

    app.filter('htmlEscape', [
        function () {
            return function (input) {
                if (input && input.length) {
                    return _.escape(input);
                }
                return null;
            }
        }
    ]);
})();