﻿(() => {
    'use strict';

    angular
        .module('App.Catalog')
        .factory('CatalogSearchService', [CatalogSearchService]);

    function CatalogSearchService() {
        let searchState = {};

        return {
            setSearchState: setSearchState,
            getSearchState: getSearchState,
            cleanSearchState: cleanSearchState,
            isSearchActive: isSearchActive
        }
        function setSearchState(state) {
            searchState = state;
        }

        function getSearchState() {
            return searchState
        }

        function cleanSearchState() {
            searchState = {};
        }
        
        function isSearchActive() {
            return (searchState && searchState.searchText && searchState.searchText.length > 0) === true;
        }
    }
})();