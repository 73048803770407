﻿(() => {
    'use strict';

    angular.module('App')
        .component('surveyBreakdown', {
            template: require('./SurveyBreakdownComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$timeout', 'Profile', SurveyBreakdownController],
            bindings: {
                response: '=',
                doneMessage: '<',
                onDone: '&'
            },
        });

    function SurveyBreakdownController($timeout, Profile) {
        const ctrl = this;

        ctrl.isHidden = true;

        ctrl.$onInit = init;
        ctrl.done = done;
        ctrl.getCurrentQuestionText = getCurrentQuestionText;
        ctrl.getOptionText = getOptionText;

        function init() {
            $timeout(function () {
                let questionIndex = 1;
                ctrl.isHidden = false;

                ctrl.firstName = Profile.getProfile().Name.split(' ', 1)[0];
                ctrl.questionResponses = ctrl.response.QuestionResponses.filter(item => {
                    if (![4].includes(item.Question.SurveyQuestionTypeId)) {
                        item.QuestionNumber = questionIndex;
                        questionIndex++;

                        return true;
                    }
                });
                ctrl.correctCount = ctrl.questionResponses.filter(question => question.IsCorrect).length
                ctrl.questionCount = ctrl.questionResponses.length
            }, 50);
        }

        function getCurrentQuestionText(translatableEntity) {
            return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                translatableEntity.LocalizedAlternativeLocale.QuestionText : translatableEntity.LocalizedLocale.QuestionText;
        }

        function getOptionText(option, question) {
            const translatedOption = question.AnswerOptions.find(currentOption => currentOption.SurveyAnswerOptionId === option.SurveyAnswerOptionId).TranslatableEntity;
            return translatedOption ? (translatedOption.PreferTranslatedVersion && translatedOption.LocalizedAlternativeLocale ?
                translatedOption.LocalizedAlternativeLocale.Text : translatedOption.LocalizedLocale.Text) : option.Text;
        }

        function done() {
            if (!ctrl.submitted) {
                ctrl.submitted = true;
            } else {
                ctrl.onDone();
            }
        }
    }
})();