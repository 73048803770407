﻿(() => {
    'use strict';

    angular
        .module('App.Survey')
        .component('commentCardComponent', {
            template: require('./CommentCardComponent.html'),
            controllerAs: 'ctrl',
            controller: ['SurveyService', CommentCardController],
            bindings: {
                comment: '<',
                hideSentimentIcon: '<',
                isLoaded: '<'
            }
        })


    function CommentCardController(SurveyService) {
        const ctrl = this;

        ctrl.commentSentimentAnalysisIconMapper = commentSentimentAnalysisIconMapper;
        
        function commentSentimentAnalysisIconMapper(sentimentType) {
            switch (sentimentType) {
                case 0:
                    return SurveyService.SENTIMENT_ICONS.positive;
                case 1:
                    return SurveyService.SENTIMENT_ICONS.neutral;
                case 2:
                    return SurveyService.SENTIMENT_ICONS.negative;
                case 3:
                    return SurveyService.SENTIMENT_ICONS.mixed;
                case 4:
                    return SurveyService.SENTIMENT_ICONS.na;
                default:
                    return SurveyService.SENTIMENT_ICONS.na;
            }
        }
    }
})();