﻿(() => {
    angular
        .module('App.Social')
        .controller('SocialOverviewController', ['socialSettings', 'Page', SocialOverviewController])

    function SocialOverviewController(socialSettings, Page) {
        const ctrl = this;

        ctrl.socialSettings = socialSettings;
        
        Page.showBackButton();
        Page.setTitle(socialSettings.PageTitle, 'SOCIAL.GROUPS.SELECT');
    }
})();