﻿(function () {
    'use strict';
    var app = angular.module('App.Filters');

    app.filter('find', [
        function () {
            return function (input, value, property) {
                for (var i = 0; i < input.length; i++) {
                    var item = input[i];
                    if (item[property] == value) {
                        return item;
                    }
                }

                return null;
            }
        }
    ]);
})();