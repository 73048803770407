﻿(() => {
    'use strict';

    angular
        .module('App.Survey')
        .factory('SurveyService', ['$state', '$stateParams', '$http', '$translate', '$filter', 'ActionPopupService',
            'PopupWrapperService', 'Page', 'ResponsiveService', 'HeaderButtonsFactory', SurveyService]);

    function SurveyService($state, $stateParams, $http, $translate, $filter, ActionPopupService, PopupWrapperService, Page,
                           ResponsiveService, HeaderButtonsFactory) {
        const
            TEXTS = {
                NoQuizzes: 'SURVEY.NO_QUIZZES',
                QuizAnswered: 'SURVEY.NO_QUIZZES',
                QuizDoneTitle: 'SURVEY.QUIZ_DONE_TITLE',
                QuizDoneText: 'SURVEY.QUIZ_DONE_TEXT',
                SurveyDoneTitle: 'SURVEY.SURVEY_DONE_TITLE',
                SurveyDoneText: 'SURVEY.SURVEY_DONE_TEXT'
            },
            TAB_TRANSLATIONS = {
                'SURVEY': $filter('translate')('SURVEY.SURVEY_TAB'),
                'MANAGER': $filter('translate')('SURVEY.MANAGER_VIEW_TAB'),
                'ACTIVE': $filter('translate')('SURVEY.ACTIVE_SURVEYS_TAB'),
                'EXPIRED': $filter('translate')('SURVEY.EXPIRED_SURVEYS_TAB'),
                'COMMENTS': $filter('translate')('COMMENTS.TITLE'),
            },
            SURVEY_PAGE_TABS = {
                'SURVEY': 0,
                'MANAGER_VIEW': 1,
                'COMMENTS': 2,
            },
            RATING_IMAGES_BASE_URL = '/Content/Graphics/Survey/',
            RATING = {
                null: 'na',
                1: 'negative',
                2: 'neutral',
                3: 'positive'
            },
            SENTIMENT_BASE_URL=  'Content/Graphics/Survey/Sentiments/',
            SENTIMENT_ICONS= {
                positive: `${SENTIMENT_BASE_URL}/thumbs_up.png`,
                negative: `${SENTIMENT_BASE_URL}/thumbs_down.png`,
                neutral: `${SENTIMENT_BASE_URL}/neutral_face.png`,
                mixed: `${SENTIMENT_BASE_URL}/thinking_face.png`,
                na: `${SENTIMENT_BASE_URL}/writing_hand.png`
            },
            DRIVER_IMAGES_BASE_URL = '/Content/Graphics/Survey/SurveyDriverIcons/',
            DRIVER_IMAGES = {
                1: 'office-building.png',
                2: 'star.png',
                3: 'spiral-calendar.png',
                4: 'laptop.png',
                5: 'speech-balloon.png',
                6: 'person-running.png',
                7: 'balance-scale.png',
                8: 'handshake.png',
                9: 'busts-in-silhouette.png',
                10: 'raising-hands.png',
                11: 'smiling-face-with-open-hands.png',
                12: 'bust-in-silhouette.png',
                13: 'raising-hands.png',
                14: 'handshake.png',
                15: 'speech-balloon.png',
                16: 'glowing-star.png',
                17: 'grinning-face-with-smiling-eyes.png',
                18: 'glowing-star.png',
                19: 'star-struck.png',
                20: 'star.png',
                21: 'puzzle-piece.png',
                22: 'gem-stone.png',
                23: 'books.png',
                24: 'chart-increasing.png',
                25: 'glowing-star.png',
                26: 'trophy.png',
                27: 'star.png',
                28: 'handshake.png',
                29: 'speech-balloon.png',
                30: 'balance-scale.png',
                31: 'rocket.png',
                32: 'bullseye.png',
                33: 'bar-chart.png',
                34: 'clapping-hands.png',
                35: 'military-medal.png',
                36: 'flexed-biceps.png',
                37: 'bullseye.png',
                38: 'envelope.png',
                39: 'speech-balloon.png',
                40: 'envelope-with-arrow.png',
                41: 'hammer-with-wrench.png',
                42: 'hourglass-not-done.png',
                43: 'bar-chart.png',
                null: 'question.png'
            };


        return {
            getTranslations: getTranslations,
            getManagerTabData: getManagerTabData,
            openFiltersPopup: openFiltersPopup,
            initParticipationRateChart: initParticipationRateChart,
            getQuestionInsights: getQuestionInsights,
            getDriverIcon: getDriverIcon,
            getRatingClass: getRatingClass,
            getRatingIcon: getRatingIcon,
            showFinishPopup: showFinishPopup,
            setHeaderButtons: setHeaderButtons,
            setFilters: setFilters,
            changeTab: changeTab,
            getQuestionsWithCommentsCount: getQuestionsWithCommentsCount,
            getQuestionComments: getQuestionComments,
            SURVEY_PAGE_TABS,
            TAB_TRANSLATIONS,
            RATING,
            SENTIMENT_ICONS
        }

        function getTranslations(settings, menuTitle) {
            const translations = {};

            translations.NoQuizzes = settings.NoSurveyText ?
                settings.NoSurveyText :
                $filter('translate')(TEXTS.NoQuizzes).replace('[PAGETITLE]', menuTitle);

            translations.QuizAnswered = settings.SurveyAnsweredText ?
                settings.SurveyAnsweredText :
                $filter('translate')(TEXTS.QuizAnswered).replace('[PAGETITLE]', menuTitle);

            translations.QuizDoneTitle = settings.SurveyDoneTitle ?
                settings.SurveyDoneTitle :
                $filter('translate')(TEXTS.QuizDoneText);

            translations.QuizDoneText = settings.SurveyDoneText ?
                settings.SurveyDoneText :
                $filter('translate')(TEXTS.QuizDoneText);

            translations.SurveyDoneTitle = settings.SurveyDoneTitle ?
                settings.SurveyDoneTitle :
                $filter('translate')(TEXTS.SurveyDoneTitle);

            translations.SurveyDoneText = settings.SurveyDoneText ?
                settings.SurveyDoneText :
                $filter('translate')(TEXTS.SurveyDoneText);

            return translations
        }

        function getManagerTabData(surveyToken, surveyInstanceToken, departmentToken, userGroupIds, isDirectReports) {
            const url = departmentToken?.length ?
                `/survey/getDepartmentInsights` :
                isDirectReports ?
                    `/survey/getDirectReportInsights` :
                    `/survey/getInsights`;

            return $http.post(url, {
                surveyToken,
                surveyInstanceToken,
                departmentToken,
                userGroupIds,
            }).then(resp => resp.data);
        }

        function openFiltersPopup({userGroups, model, onFiltersApply}) {
            PopupWrapperService.createDynamic(
                `<survey-filters-popup on-filters-apply="onFiltersApply" user-groups="userGroups" filters-model="model"></survey-filters-popup>`,
                {
                    onFiltersApply,
                    userGroups,
                    model
                }
            );
        }

        function getQuestionInsights(surveyToken, surveyInstanceToken, departmentToken, questionId, userGroupIds, isDirectReports) {
            return $http
                .post('/survey/getQuestionInsights', {
                    surveyToken,
                    surveyInstanceToken,
                    departmentToken,
                    questionId,
                    userGroupIds,
                    directReport: isDirectReports
                })
                .then(resp => resp.data);
        }

        function getDriverIcon(id) {
            return DRIVER_IMAGES_BASE_URL + DRIVER_IMAGES[id];
        }

        function getRatingClass(RateType) {
            return RATING[RateType];
        }

        function getRatingIcon(RateType) {
            return RATING_IMAGES_BASE_URL + RATING[RateType] + '.png';
        }

        function showFinishPopup(onFinishClick, translations) {
            ActionPopupService.create(
                '<survey-finish-popup on-finish-click="onFinishClick" translations="translations"></survey-finish-popup>',
                {onFinishClick, translations}
            );
        }

        function setHeaderButtons(ctrl) {
            if (ResponsiveService.isDesktop() && ctrl.isTabsAvaliable) {
                setSubtitle(ctrl.activeTab);
            }

            HeaderButtonsFactory.createButtonsList(() => getHeaderButtons())

            function getHeaderButtons() {
                const items = [];
                const titleItems = [];

                if (ctrl.surveyAvaliable) {
                    titleItems.push({
                        title: TAB_TRANSLATIONS.SURVEY,
                        onClick: () => {
                            ctrl.changeTab(SURVEY_PAGE_TABS.SURVEY)
                        },
                        active: ctrl.activeTab === ctrl.TABS.SURVEY
                    })
                }
                if (ctrl.managerViewAvailable) {
                    titleItems.push({
                        title: TAB_TRANSLATIONS.MANAGER,
                        onClick: () => {
                            ctrl.changeTab(SURVEY_PAGE_TABS.MANAGER_VIEW)
                        },
                        active: ctrl.activeTab === ctrl.TABS.MANAGER_VIEW
                    })
                }
                if (ctrl.commentsEnabled) {
                    titleItems.push({
                        title: TAB_TRANSLATIONS.COMMENTS,
                        onClick: () => {
                            ctrl.changeTab(SURVEY_PAGE_TABS.COMMENTS)
                        },
                        active: ctrl.activeTab === ctrl.TABS.COMMENTS
                    })
                }

                if (ResponsiveService.isDesktop() && ctrl.isTabsAvaliable) {
                    items.push({
                        icon: 'arrow-bottom',
                        activeIcon: 'arrow-top',
                        place: 'title',
                        items: titleItems
                    })
                }

                if (ctrl.surveyData.ManagerViewSettings.UserGroups?.length) {
                    items.push({
                        icon: 'filters',
                        activeIcon: 'filters-active',
                        hidden: ctrl.activeTab === ctrl.TABS.SURVEY,
                        onClick: ctrl.openFilters,
                        place: 'right',
                        active: ctrl.surveyData.filters?.UserGroupIds?.length
                    })
                }

                return items
            }
        }

        function setFilters(openFilters, filters) {
            HeaderButtonsFactory.createButtonsList(() => getFilters())

            function getFilters() {
                return [{
                    icon: 'filters',
                    activeIcon: 'filters-active',
                    onClick: openFilters,
                    place: 'right',
                    active: filters?.UserGroupIds?.length
                }]
            }
        }

        function changeTab(ctrl) {
            Page.stateGo($state.current.name, $stateParams.token, $stateParams.extra, null, ctrl.surveyData, false, {tab: getTabRestParam(ctrl.activeTab)});

            setHeaderButtons(ctrl);

            function getTabRestParam(tab) {
                switch (tab) {
                    case SURVEY_PAGE_TABS.SURVEY:
                        return null
                    case SURVEY_PAGE_TABS.MANAGER_VIEW:
                        return 'manager'
                    case SURVEY_PAGE_TABS.COMMENTS:
                        return 'comments'
                }
            }
        }

        function getQuestionsWithCommentsCount({surveyToken, surveyInstanceToken, userGroupIds, departmentToken, directReport}) {
            return $http
                .post(`/survey/getQuestionsWithCommentsCount`, {
                    surveyToken,
                    surveyInstanceToken,
                    userGroupIds,
                    departmentToken,
                    directReport
                })
                .then(resp => resp.data)
        }

        function getQuestionComments(surveyQuestionToken, surveyInstanceToken, departmentToken, UserGroupIds, isDirectReports) {
            return $http
                .post('/survey/getQuestionComments/', {surveyQuestionToken, surveyInstanceToken, departmentToken, UserGroupIds, directReport: isDirectReports})
                .then(resp => resp.data);
        }

        function setSubtitle(tab) {
            Page.setSubTitle(getSubtitleTranslation(tab));

            function getSubtitleTranslation(tab) {
                switch (tab) {
                    case SURVEY_PAGE_TABS.SURVEY:
                        return TAB_TRANSLATIONS.SURVEY
                    case SURVEY_PAGE_TABS.MANAGER_VIEW:
                        return TAB_TRANSLATIONS.MANAGER
                    case SURVEY_PAGE_TABS.COMMENTS:
                        return TAB_TRANSLATIONS.COMMENTS
                }
            }
        }

        function initParticipationRateChart(element, participationRate) {
            const options = {
                chart: {
                    type: 'pie',
                    spacing: [0, 0, 0, 0],
                    plotShadow: false,
                    backgroundColor: 'var(--general-background-color)'
                },
                colors: ['rgba(var(--highlight-color-rgb), 1)', 'rgba(var(--highlight-color-rgb), 0.25)'],
                title: {
                    text: ''
                },
                legend: {
                    enabled: false,
                },
                series: [{
                    enableMouseTracking: false,
                    states: {
                        inactive: {
                            opacity: 1
                        }
                    },
                    data: []
                }],
                credits: {enabled: false},
                plotOptions: {
                    series: {
                        allowPointSelect: true
                    },
                    pie: {
                        innerSize: '67%',
                        size: 150,
                        borderColor: 'var(--general-background-color)',
                        dataLabels: {
                            enabled: false
                        }
                    }
                }
            }

            if (!participationRate) {
                participationRate = 0;
            }

            options.series[0].data.push({y: participationRate});
            if (participationRate !== 100) {
                options.series[0].data.push({y: 100 - participationRate});
            } else {
                options.plotOptions.pie.borderWidth = 0;
                options.plotOptions.pie.innerSize = '80%';
            }

            Highcharts.chart(element, options);
        }
    }
})();