﻿(() => {
    'use strict';

    angular
        .module('App.GlobalSearch')
        .component('filterByModulePopupComponent', {
            template: require('./FilterByModulePopupComponent.html'),
            controller: ['$scope', '$element', 'GLOBAL_SEARCH_MODULE_LABEL', FilterByModulePopupController],
            controllerAs: 'ctrl',
            bindings: {
                onModuleFilterSelected: '<',
                options: '<',
                onClearFilter: '<'
            }
        });

    function FilterByModulePopupController($scope, $element) {
        const ctrl = this;

        ctrl.$onInit = init;
        ctrl.select = select;
        ctrl.clearFilter = clearFilter;
        ctrl.submit = submit;
        ctrl.onClose = onClose;

        function init() {
            ctrl.internalOptions = _.cloneDeep(ctrl.options);
        }

        function select(items) {
            ctrl.internalOptions = items;
        }

        function clearFilter() {
            ctrl.onClearFilter && ctrl.onClearFilter();
            ctrl.closeAction && ctrl.closeAction();
        }
        
        function submit() {
            ctrl.onModuleFilterSelected && ctrl.onModuleFilterSelected(ctrl.internalOptions);
            ctrl.closeAction && ctrl.closeAction();
        }

        function onClose() {
            $scope.$destroy();
            $element.remove();
        }
    }
})();