﻿(() => {
    'use strict';

    angular
        .module('App.Catalog')
        .component('catalogSearch', {
            template: require('./CatalogSearchComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', '$timeout', '$state', '$scope', '$stateParams', '$filter', 'Page', 'CatalogFactory',
                'CatalogSearchService', 'TrackingService', CatalogSearchController],
            bindings: {
                settings: '<',
                searchPlaceholder: '<',
                isSearchActive: '=?',
                isSearching: '=?'
            }
        });

    function CatalogSearchController($rootScope, $timeout, $state, $scope, $stateParams, $filter, Page, CatalogFactory,
                                     CatalogSearchService, TrackingService) {
        const ctrl = this;
        let destroyBarcodeWatch, destroySearchStringWatch, destroyResetSearchWatch;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.clearBarcode = clearBarcode;
        ctrl.onSearch = onSearch;
        ctrl.paging = paging;
        ctrl.openItem = openItem;
        ctrl.getSearchPlaceholder = getSearchPlaceholder;

        function init() {
            ctrl.searchQuery = {};
            if ($stateParams.data.showSearch && (CatalogSearchService.isSearchActive() || $stateParams.data.searchQuery?.searchText)) {
                ctrl.searchQuery = CatalogSearchService.getSearchState()?.searchText ?
                    CatalogSearchService.getSearchState() : $stateParams.data.searchQuery;
                ctrl.isSearchActive = true;

                TrackingService.trackPageView(ctrl.settings.entityType, ctrl.settings.entityId, 'SearchResult');

                if (ctrl.searchQuery.scrollTop) {
                    ctrl.hideResults = true;
                    $timeout(() => {
                        document.querySelector('html').scrollTop = ctrl.searchQuery.scrollTop;
                        $timeout(() => {
                            ctrl.hideResults = false;
                        })
                    }, 300);
                }
            }

            initWatchers();
        }

        function initWatchers() {
            destroyBarcodeWatch = $scope.$watch('ctrl.barcode', () => {
                if (ctrl.barcode) {
                    ctrl.searchString = ctrl.barcode;
                    onSearch(ctrl.barcode);
                }
            }, true)

            destroySearchStringWatch = $scope.$watch('ctrl.searchQuery.searchText', (str) => {
                if (str?.length === 0) {
                    onSearch('');
                }
            }, true)

            destroyResetSearchWatch = $rootScope.$on('CATALOG.RESET_SEARCH', () => {
                CatalogSearchService.cleanSearchState();
                Page.stateGo($state.current?.name, $state.params?.token, $state.params?.extra, null, false)
                ctrl.searchQuery = {};
                ctrl.noResults = false;
            })
        }

        function onSearch(string) {
            ctrl.noResults = false;
            ctrl.isSearching = true;

            delete ctrl.searchQuery.afterEntityType;
            delete ctrl.searchQuery.afterEntityId;

            if (string?.length) {
                ctrl.isSearchActive = true;
                ctrl.searchQuery.searchText = string;
                CatalogFactory
                    .search(ctrl.searchQuery, ctrl.settings.accountModuleId)
                    .then(data => {
                        TrackingService.trackPageView(ctrl.settings.entityType, ctrl.settings.entityId, 'SearchResult');

                        ctrl.isSearching = false;
                        ctrl.searchQuery.afterEntityType = data.BatchLastEntityType;
                        ctrl.searchQuery.afterEntityId = data.BatchLastEntityId;
                        ctrl.searchQuery.items = data.Items;

                        if (ctrl.searchQuery.items.length === 0) {
                            ctrl.noResults = true;
                        }
                    })
                    .finally(() => Page.stateGo($state.current?.name, $state.params?.token,
                        $state.params?.extra, {showSearch: true, searchQuery: ctrl.searchQuery}, false));
            } else {
                if (ctrl.isSearchActive) {
                    TrackingService.trackPageView(ctrl.settings.entityType, ctrl.settings.entityId, ctrl.settings.viewName);
                }

                ctrl.searchQuery.items = [];
                ctrl.isSearchActive = false;
                ctrl.isSearching = false;
                ctrl.noResults = false;
            }

            $timeout(() => {
                document.querySelector('html').scrollTop = 0;
            });
        }

        function paging() {
            if (!ctrl.isSearching) {
                ctrl.isSearching = true;
                CatalogFactory
                    .search({...ctrl.searchQuery, limit: 10}, ctrl.settings.accountModuleId)
                    .then(data => {
                        ctrl.searchQuery.afterEntityType = data.BatchLastEntityType;
                        ctrl.searchQuery.afterEntityId = data.BatchLastEntityId;
                        ctrl.searchQuery.items.push(...data.Items);

                        $timeout(() => {
                            ctrl.isSearching = false;
                        })
                    })
                    .catch(() => {
                        ToastFactory.error();
                    });
            }
        }

        function clearBarcode() {
            ctrl.barcode = '';
        }

        function openItem(item) {
            ctrl.searchQuery.scrollTop = document.querySelector('html').scrollTop;
            CatalogSearchService.setSearchState(ctrl.searchQuery);
            Page.stateGoLinkV2(item.EntityLink);
        }

        function getSearchPlaceholder() {
            let placeholder = $filter('translate')('CONTENT.SEARCH_IN');

            if (ctrl.searchPlaceholder) {
                placeholder += ' ' + $filter('translate')(ctrl.searchPlaceholder);
            } else {
                placeholder = $filter('translate')('SEARCH.CATALOG_PLACEHOLDER');
            }

            return placeholder;
        }

        function destroy() {
            destroyBarcodeWatch && destroyBarcodeWatch();
            destroySearchStringWatch && destroySearchStringWatch();
            destroyResetSearchWatch && destroyResetSearchWatch();
        }
    }
})();