﻿(() => {
    'use strict';

    angular
        .module(
            'App.Catalog',
            ['App.Common', 'App.Basics']
        );


    require('./CatalogComponent');
    require('./CatalogController.js');
    require('./CatalogCategory/CatalogCategoryController');
    require('./CatalogCategory/CatalogCategoryComponent');
    
    require('./Items/CategoriesList/CategoriesListComponent');
    require('./Items/CatalogItemDetails/CatalogItemDetailsComponent');
    require('./Items/CatalogItemDetails/ItemDetailsController');
    require('./Items/CatalogItemsList/CatalogItemsListComponent');
    require('./Items/CatalogGridItem/CatalogGridItemComponent');
    
    require('./CatalogSearch/CatalogSearchComponent');
    require('./CatalogSearch/CatalogSearchList/CatalogSearchListComponent');
    require('./CatalogSearchService');
    require('./CatalogFactory');
})();