﻿(() => {
    'use strict';

    angular.module('App.Survey')
        .component('survey', {
            template: require('./SurveyComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$rootScope', 'Page', '$stateParams', '$timeout', '$http', '$state', 'Menu',
                'ToastFactory', 'SurveyService', SurveyController],
            bindings: {
                survey: '=',
                settings: '<',
                withTabs: '<',
                title: '@',
                menuTitle: '@',
                surveyQuestionAnswers: '<'
            }
        });

    function SurveyController($rootScope, Page, $stateParams, $timeout, $http, $state, Menu, ToastFactory,
                              SurveyService) {
        const ctrl = this;

        ctrl.currentQuestion = 0;
        ctrl.currentQuestionIndex = 0;
        ctrl.enpsQuestionAnswer = null;
        ctrl.answers = [];
        ctrl.currentQuestionAnswers = [];
        ctrl.isLocked = false;
        ctrl.canSubmit = false;
        ctrl.isRTL = $rootScope.isRTL;

        ctrl.$onInit = init;
        ctrl.completeSurvey = completeSurvey;
        ctrl.back = back;
        ctrl.onOptionSelect = onOptionSelect;
        ctrl.nextQuestion = nextQuestion
        ctrl.leaveSurvey = leaveSurvey;

        function init() {
            ctrl.translates = SurveyService.getTranslations(ctrl.settings, ctrl.menuTitle);

            if (ctrl.settings.EnableAllOnOnePage) {
                setSubTitle();
            }

            if (ctrl.survey) {
                ctrl.navigationBadge = ctrl.survey.NavigationBadge;

                ctrl.survey.Questions = setQuestionNumbers(ctrl.survey.Questions)

                // Show first question
                if (!ctrl.survey.IsAnswered) {
                    if (ctrl.surveyQuestionAnswers?.length > 0) {
                        var lastAnsweredQuestion = ctrl.surveyQuestionAnswers[ctrl.surveyQuestionAnswers.length - 1].SurveyQuestionId;
                        var lastAnsweredQuestionIndex = ctrl.survey.Questions.indexOf(ctrl.survey.Questions.find(q => q.SurveyQuestionId === lastAnsweredQuestion));
                        ctrl.currentQuestionIndex = ctrl.survey.Questions.length - 1 === lastAnsweredQuestionIndex ? lastAnsweredQuestionIndex : lastAnsweredQuestionIndex + 1;
                        ctrl.currentQuestion = ctrl.survey.Questions[ctrl.currentQuestionIndex].SurveyQuestionId;
                        loadAnswers();
                    } else {
                        nextQuestion(true);
                    }
                }
            }
        }

        function setSubTitle() {
            const answeredCount = ctrl.survey.Questions.filter(question => {
                return question.answered || question.SurveyQuestionTypeId === 4
            }).length;

            if (ctrl.survey.Questions.length > 0) {
                Page.setSubTitle(answeredCount + '/' + ctrl.survey.Questions.length);
            }
        }

        function onOptionSelect(question) {
            if (ctrl.settings.EnableAllOnOnePage) {
                answerQuestion(question.SurveyQuestionId);
            }

            selectOption(question);
        }

        function selectOption(question){
            if ((ctrl.settings.AllowQuickFlow && !ctrl.settings.EnableAllOnOnePage)
                && !isMultiple(question)
                && ctrl.survey.UseQuickFlow
                && question.SurveyQuestionTypeId !== 3
                && question.SurveyQuestionTypeId !== 4
                && question.SurveyQuestionTypeId !== 5) {
                nextQuestion();
            }

            setData();
        }

        function setData() {
            const requiredQuestions = ctrl.survey.Questions.filter(question => question.IsRequired),
                answeredRequiredQuestions = requiredQuestions.filter(question => question.answered);

            if (ctrl.settings.EnableAllOnOnePage) {
                setSubTitle()
            }

            ctrl.canSubmit = requiredQuestions.length === answeredRequiredQuestions.length;
        }

        // Methods
        function leaveSurvey() {
            Page.stateGoBack();
        }

        function nextQuestion(showFirst) {
            const isEnd = (ctrl.currentQuestionIndex === (ctrl.survey.Questions.length - 1)) && !showFirst;

            if (!isEnd) {
                if (ctrl.currentQuestion === 0) {
                    ctrl.currentQuestion = ctrl.survey.Questions[0].SurveyQuestionId;

                    $timeout(function () {
                        const nextQuestion = ctrl.survey.Questions[0];

                        // Set as answered if not required
                        if (nextQuestion.SurveyQuestionTypeId === 4 || (nextQuestion.SurveyQuestionTypeId === 3 && !nextQuestion.IsRequired)) {
                            nextQuestion.answered = true;
                            nextQuestion.AnswerText = '';
                            if (nextQuestion.AnswerOptions.length > 0)
                                nextQuestion.AnswerOptions[0].picked = true;
                        }
                    });
                } else {
                    answerQuestion(ctrl.currentQuestion);

                    const nextQuestion = ctrl.survey.Questions[(ctrl.currentQuestionIndex + 1)];
                    // Select the next question
                    ctrl.currentQuestion = nextQuestion.SurveyQuestionId;
                    // Increase the index of the currently selected question
                    ctrl.currentQuestionIndex++;

                    // Set as answered if not required
                    if (nextQuestion.SurveyQuestionTypeId === 4 || (nextQuestion.SurveyQuestionTypeId === 3 && !nextQuestion.IsRequired)) {
                        nextQuestion.answered = true;
                        nextQuestion.AnswerText = '';
                        if (nextQuestion.AnswerOptions.length > 0)
                            nextQuestion.AnswerOptions[0].picked = true;
                    }
                }
            } else {
                completeSurvey();
            }
        }

        function back() {
            $timeout(() => {
                $timeout(() => {
                    ctrl.currentQuestionIndex--;

                    // Remove last answer
                    ctrl.answers.pop();

                    // Set the new question to be shown
                    ctrl.currentQuestion = ctrl.survey.Questions[ctrl.currentQuestionIndex].SurveyQuestionId;
                });
            }, 100);
        }

        function answerQuestion(surveyQuestionId) {
            prepareQuestionAnswers(surveyQuestionId);
            if (!ctrl.isLocked) {
                ctrl.isLocked = true;

                $http.post('/Survey/AnswerQuestion', {
                    Token: ctrl.survey.SurveyToken,
                    SurveyQuestionId: surveyQuestionId,
                    Answers: ctrl.currentQuestionAnswers
                }).then(response => {
                    ctrl.isLocked = false;
                }).catch(() => {
                    ctrl.isLocked = false;
                    // Remove last answer as it was not saved correctly
                    ctrl.answers.pop();
                    ToastFactory.error('ERROR.GENERAL');
                });
            }
        }

        function completeSurvey() {
            prepareAnswers();
            if (!ctrl.isLocked) {
                ctrl.isLocked = true;
                Page.startLoading();

                $http.post('/Survey/AnswerSurvey', {
                    Token: ctrl.survey.SurveyToken,
                    Answers: ctrl.answers
                }).then(response => {
                    const responseData = response.data;
                    Page.stopLoading();
                    Menu.updateActivity(0, $state.current.name, $state.params.token, ctrl.survey.SurveyToken);

                    const navBadge = ctrl.survey.NavigationBadge;
                    Menu.removeLandingBadge(1, navBadge.AccountModuleId, navBadge.ModuleId, navBadge.ModuleItemId);

                    ctrl.isLocked = false;
                    ctrl.pointsGained = responseData.Points;
                    if (ctrl.settings.SurveyTypeId === 1) {
                        // Show breakdown
                        //validateAnswers(responseData);
                        ctrl.responseData = responseData;
                        ctrl.quizDoneText = ctrl.translates.QuizDoneText.replace('[POINT]', ctrl.responseData.Points)
                        ctrl.showBreakdown = true;
                    } else {
                        SurveyService.showFinishPopup(leaveSurvey, ctrl.translates);
                    }
                }).catch(() => {
                    ctrl.isLocked = false;
                    // Remove last answer as it was not saved correctly
                    ctrl.answers.pop();
                    ToastFactory.error('ERROR.GENERAL');
                });
            }
        }

        function prepareQuestionAnswers(surveyQuestionId) {
            ctrl.currentQuestionAnswers = [];
            ctrl.survey.Questions.filter(q => q.SurveyQuestionId === surveyQuestionId)
            .forEach(question => {
                if (question.SurveyQuestionTypeId !== 4) {
                    // get all selected answer options
                    question.AnswerOptions
                        .filter(option => option.picked)
                        .forEach(option => {
                            const text = (question.SurveyQuestionTypeId === 3) ? question.AnswerText : option.Text;

                            ctrl.currentQuestionAnswers.push({
                                SurveyAnswerOptionId: option.SurveyAnswerOptionId,
                                AnswerText: text,
                                AnswerNumeric: option.Value,
                                SurveyQuestionId: question.SurveyQuestionId
                            });
                        });
                } else {
                    ctrl.currentQuestionAnswers.push(null);
                }
            })
        }

        function prepareAnswers() {
            ctrl.survey.Questions.forEach(question => {
                if (question.SurveyQuestionTypeId !== 4) {
                    // get all selected answer options
                    question.AnswerOptions
                        .filter(option => option.picked)
                        .forEach(option => {
                            const text = (question.SurveyQuestionTypeId === 3) ? question.AnswerText : option.Text;

                            ctrl.answers.push({
                                SurveyAnswerOptionId: option.SurveyAnswerOptionId,
                                AnswerText: text,
                                AnswerNumeric: option.Value,
                                SurveyQuestionId: question.SurveyQuestionId
                            });
                        });
                } else {
                    ctrl.answers.push(null);
                }
            })
        }

        function loadAnswers() {
            ctrl.surveyQuestionAnswers.forEach(answer => {
                ctrl.answers.push({
                    SurveyAnswerOptionId: answer.SurveyAnswerOptionId,
                    AnswerText: answer.AnswerText,
                    AnswerNumeric: answer.AnswerNumeric,
                    SurveyQuestionId: answer.SurveyQuestionId
                });

                var answeredQuestion = ctrl.survey.Questions.find(question => {
                    return question.SurveyQuestionId === answer.SurveyQuestionId
                });

                answeredQuestion.answered = true;

                _.each(answeredQuestion?.AnswerOptions, function (opt) {
                    if (opt.SurveyAnswerOptionId === answer.SurveyAnswerOptionId) {
                        setData();
                        opt.picked = true;
                        opt.marked = true;
                    }

                    if (answeredQuestion?.SurveyQuestionTypeId === 3) {
                        answeredQuestion.AnswerText = answer.AnswerText;
                    }
                });
            })
        }

        function isMultiple(question) {
            if (question.SurveyQuestionTypeId === 2) {
                return (question.MaxSelectedOptions > 1);
            }
            return false;
        }

        function setQuestionNumbers(questions) {
            let ind = 1;

            questions.forEach(question => {
                if (question.SurveyQuestionTypeId !== 4) {
                    question.QuestionNumber = ind;
                    ind++;
                }
            });

            return questions;
        }
    }
})();