﻿(() => {
    'use strict';

    angular
        .module('App.GlobalSearch')
        .component('searchPage', {
            controllerAs: 'ctrl',
            template: require('./GlobalSearchPageComponent.html'),
            controller: ['$http', '$element', '$timeout', 'ToastFactory', 'Page', 'GlobalSearchService', SearchPageController]
        });

    function SearchPageController($http, $element, $timeout, ToastFactory, Page, GlobalSearchService) {
        const ctrl = this;
        let promiseList = [];

        ctrl.$onInit = init;
        ctrl.onSearch = onSearch;
        ctrl.paging = paging;
        ctrl.openItem = openItem;
        ctrl.onDateFilterSelected = onDateFilterSelected;
        ctrl.onModuleFilterSelected = onModuleFilterSelected;
        ctrl.onSort = onSort;
        ctrl.isFilterActive = isFilterActive;
        ctrl.resetFilters = resetFilters;
        ctrl.resetModuleFilter = resetModuleFilter;
        ctrl.isMultipleFiltersActive = isMultipleFiltersActive;
        ctrl.isModuleSelected = isModuleSelected;

        function init() {
            ctrl.searchQuery = {};
            ctrl.processing = true;

            $http.get('/SearchV2/GetSearchPage').then(({data}) => {
                ctrl.options = GlobalSearchService.getFilterByModuleOptions(data.Modules);

                if (GlobalSearchService.getSearchState().searchText) {
                    ctrl.options = ctrl.options.map(option => ({
                        ...option,
                        selected: GlobalSearchService.getSearchState().modules?.includes(option.id)
                    }))
                }
                ctrl.processing = false;
            });
            ctrl.focusSearch = !ctrl.searchQuery.searchText;

            if (GlobalSearchService.getSearchState().searchText) {
                ctrl.searchQuery = GlobalSearchService.getSearchState();
                ctrl.hideResults = true;
                ctrl.disblePaging = !ctrl.searchQuery.batchLastEntityType;
                ctrl.searchDone = !!ctrl.searchQuery.searchText;

                $timeout(() => {
                    document.querySelector('html').scrollTop = ctrl.searchQuery.scrollTop;
                    $timeout(() => {
                        ctrl.hideResults = false;
                    })
                }, 300);
            }
        }

        function onSearch() {
            ctrl.disblePaging = false;
            delete ctrl.searchQuery.batchLastEntityId;
            delete ctrl.searchQuery.batchLastEntityType;
            if (ctrl.searchQuery.searchText?.length > 0) {
                ctrl.processing = true;
                const promise = search()
                    .then(({BatchLastEntityType, BatchLastEntityId, Items}) => {
                        document.querySelector('html').scrollTop = 0;

                        ctrl.searchQuery = {
                            ...ctrl.searchQuery,
                            searchResults: Items,
                            batchLastEntityType: BatchLastEntityType,
                            batchLastEntityId: BatchLastEntityId
                        };
                        ctrl.disblePaging = !BatchLastEntityType;
                        ctrl.searchDone = true;
                        ctrl.lastSearchText = ctrl.searchQuery.searchText;
                        GlobalSearchService.setSearchState(ctrl.searchQuery);
                        ctrl.processing = false;
                        promiseList.pop();
                    })
                    .catch((err) => {
                        if (promiseList.length <= 1) {
                            ctrl.processing = false;
                        }
                        if (err.status !== -1) {
                            ToastFactory.error();
                        }
                        //Cancel request dont provide a message, so need this workaround with counting promises
                        promiseList.pop();
                    })
                promiseList.push(promise)
            } else {
                GlobalSearchService.cancelSearch();
                ctrl.searchQuery.searchResults = [];
                ctrl.searchDone = false;
            }
        }

        function isFilterActive() {
            return ctrl.searchQuery.selectedDateFilterId !== undefined || isModuleSelected();
        }

        function search(isPaging) {
            const model = {
                ...ctrl.searchQuery,
                modules: ctrl.searchQuery.modules,
                sortType: ctrl.searchQuery.sortType ? ctrl.searchQuery.sortType : 1, // 1 - most relevant
                afterEntityType: isPaging && ctrl.searchQuery.batchLastEntityType,
                afterEntityId: isPaging && ctrl.searchQuery.batchLastEntityId,
                limit: isPaging ? 10 : 20
            };
            
            delete model.batchLastEntityId;
            delete model.batchLastEntityType;

            return GlobalSearchService.search(model);
        }

        function paging() {
            ctrl.layzyLoading = true;
            search(true)
                .then(({BatchLastEntityType, BatchLastEntityId, Items}) => {
                    ctrl.searchQuery = {
                        ...ctrl.searchQuery,
                        searchResults: [...ctrl.searchQuery.searchResults, ...Items],
                        batchLastEntityType: BatchLastEntityType,
                        batchLastEntityId: BatchLastEntityId
                    };
                    ctrl.disblePaging = !ctrl.searchQuery.batchLastEntityType;
                })
                .catch(() => {
                    ToastFactory.error();
                })
                .finally(() => {
                    ctrl.layzyLoading = false;
                })
        }

        function openItem(item) {
            ctrl.searchQuery.scrollTop = document.querySelector('html').scrollTop;
            GlobalSearchService.setSearchState(ctrl.searchQuery);
            Page.stateGoLinkV2(item.EntityLink);
        }

        function onDateFilterSelected(selectedDateFilterId, {startDate, endDate} = {}) {
            if (selectedDateFilterId) {
                ctrl.searchQuery = {...ctrl.searchQuery, startDate, endDate, selectedDateFilterId};
            } else {
                delete ctrl.searchQuery.startDate;
                delete ctrl.searchQuery.endDate;
                delete ctrl.searchQuery.selectedDateFilterId;
            }
            GlobalSearchService.setSearchState(ctrl.searchQuery);
            onSearch();
        }

        function onModuleFilterSelected(selectedModules) {
            ctrl.options = selectedModules;
            ctrl.searchQuery.modules = selectedModules.filter((module) => module.selected).map((module) => module.id);
            onSearch();
        }

        function onSort(val) {
            ctrl.searchQuery.sortType = val.id;
            GlobalSearchService.setSearchState(ctrl.searchQuery);
            onSearch();
        }

        function resetFilters() {
            ctrl.resetDateFilter && ctrl.resetDateFilter();
            unselectModules();

            delete ctrl.searchQuery.startDate;
            delete ctrl.searchQuery.endDate;
            delete ctrl.searchQuery.selectedDateFilterId;
            delete ctrl.searchQuery.modules;

            GlobalSearchService.setSearchState(ctrl.searchQuery);
            onSearch();
        }

        function resetModuleFilter() {
            unselectModules();

            delete ctrl.searchQuery.modules;
            GlobalSearchService.setSearchState(ctrl.searchQuery);
            onSearch();
        }

        function unselectModules() {
            ctrl.options = ctrl.options.map((option) => ({
                ...option,
                selected: false
            }));
        }

        function isMultipleFiltersActive() {
            return ctrl.searchQuery.selectedDateFilterId !== undefined && isModuleSelected() || isModuleSelected(1);
        }

        function isModuleSelected(totalNumber = 0) {
            return ctrl.options?.filter((option) => option.selected).length > totalNumber;
        }
    }
})();