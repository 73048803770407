﻿(() => {
    'use strict';

    angular
        .module('App.Common')
        .factory('ToastFactory', ['$translate', '$timeout', '$rootScope', 'toastr', 'BasicHelper', 'events', 
            function ($translate, $timeout, $rootScope, toastr, BasicHelper, events) {       

                return {
                    error: error,
                    errorTranslatedReplace: errorTranslatedReplace,
                    success: success,
                    successTranslatedReplace: successTranslatedReplace,
                    warning: warning,
                    warningTranslatedReplace: warningTranslatedReplace,
                    info: info,
                    infoTranslatedReplace: infoTranslatedReplace,
                    modelStateWarning: modelStateWarning,
                    clear: clear
                }

                /*  To use variable in toast, provide config object:
                    {
                        variable: { type: 'front' | 'end' , text: string }
                    }

                    To use button(s) in toast, provide config object:
                    {
                        buttons: [
                            { text: string, onClick: ()=>{} }
                        ]
                    }
                */

                function error(message, title, config) {
                    toastr.clear();
                    const toast = toastr.error(message, title, {...toastr.options, extraData: {...config}});
                    attachKeyboardListener(toast);
                }

                function errorTranslatedReplace(message, replaceSearch, replaceText) {
                    translateReplace(message, 'error', replaceSearch, replaceText);
                }

                function success(message, title, config) {
                    toastr.clear();
                    const toast = toastr.success(message, title, {...toastr.options, extraData: {...config}});
                    attachKeyboardListener(toast);
                }

                function successTranslatedReplace(message, replaceSearch, replaceText) {
                    translateReplace(message, 'success', replaceSearch, replaceText);
                }

                function warning(message, title, config) {
                    toastr.clear();
                    const toast = toastr.warning(message, title, {...toastr.options, extraData: {...config}});
                    attachKeyboardListener(toast);
                }

                function warningTranslatedReplace(message, replaceSearch, replaceText) {
                    translateReplace(message, 'warning', replaceSearch, replaceText);
                }

                function info(message, title, config) {
                    toastr.clear();
                    const toast = toastr.info(message, title, {...toastr.options, extraData: {...config}});
                    attachKeyboardListener(toast);
                }

                function infoTranslatedReplace(message, replaceSearch, replaceText) {
                    translateReplace(message, 'info', replaceSearch, replaceText);
                }

                function modelStateWarning(response, onEmptyMessage) {
                    if (response.status === 400 && _.isArray(response.data) && !_.isEmpty(response.data)) {
                        const modelErr = _.first(response.data);
                        warning(modelErr.Message);
                    } else if (onEmptyMessage) {
                        warning(onEmptyMessage);
                    }
                }

                function clear() {
                    toastr.clear();
                }
                
                function translateReplace(message, type, replaceSearch, replaceText) {
                    $translate(message || 'ERROR.GENERAL')
                        .then(translation => {
                            translation = translation.replace(replaceSearch, replaceText);
                            const toast = toastr[type](translation);
                            attachKeyboardListener(toast);
                        })
                        .catch(() => toastr[type](message));
                }

                function attachKeyboardListener(toast) {
                    $timeout(() => {
                        if (BasicHelper.isIOS()) {
                            const toastContainer = document.querySelector('#toast-container');
                            if (!toastContainer) return;

                            moveToastUp();
                            // Listen for the events
                            const showListener = $rootScope.$on(events.KEYBOARD_DIDSHOW, moveToastUp);
                            const hideListener = $rootScope.$on(events.KEYBOARD_DIDHIDE, moveToastBackToBottom);
    
                            // Unsubscribe from the event when it's no longer needed
                            toast.scope.options.onHidden = function () {
                                showListener && showListener();
                                hideListener && hideListener();
                            };
    
                            function moveToastUp() {
                                const toastRect = toastContainer.getBoundingClientRect();
                                const keyboardTop = visualViewport.height;
                                const moveDistance = toastRect.top - keyboardTop + toastRect.height;
                                toastContainer.style.transform = `translateY(-${moveDistance}px)`;
                            };

                            function moveToastBackToBottom() {
                                toastContainer.style.transform = '';
                            };
                        }
                    }, 0)
                }
        }]);
})();