﻿// Events
function ReactorEvent(name) {
    this.name = name;
    this.callbacks = [];
};
ReactorEvent.prototype.registerCallback = function (callback, runOnce) {
    var id = '_' + Math.random().toString(36).substr(2, 9);
    this.callbacks.push({ callback: callback, runOnce: runOnce, id: id });
    return id;
};
ReactorEvent.prototype.deregisterCallback = function (id) {
    _.remove(this.callbacks, function (o) {
        return o.id === id;
    });
};

// Reactor
function Reactor() {
    this.events = {};
}

Reactor.prototype.registerEvent = function (eventName) {
    var event = new ReactorEvent(eventName);
    this.events[eventName] = event;
};

Reactor.prototype.dispatchEvent = function (eventName, eventArgs) {
    var event = this.events[eventName];
    if (_.isObject(event)) {
        _.forEach(event.callbacks, function (c) {
            if (_.isObject(c) && _.isFunction(c.callback)) {
                c.callback(eventArgs);
                if (c.runOnce) {
                    event.deregisterCallback(c.id);
                }
            }
        });
    }
};

Reactor.prototype.addEventListener = function (eventName, callback) {
    return this.events[eventName].registerCallback(callback, false);
};

Reactor.prototype.addOnceEventListener = function (eventName, callback) {
    return this.events[eventName].registerCallback(callback, true);
};

Reactor.prototype.removeEventListener = function (eventName, id) {
    this.events[eventName].deregisterCallback(id);
};

window.Reactor = Reactor;