﻿(() => {
    'use strict';

    angular
        .module('App.Social')
        .factory('SocialGroupService', ['$http', 'LocalStorageService', SocialGroupService]);

    function SocialGroupService($http, LocalStorageService) {
        const CACHE_KEY = 'SocialGroups_{socialModuleToken}_selectedSocialGroupId';
        const NULL_CACHE_VALUE = 'NULL';

        return {
            getGroups: getGroups,
            saveSelectedSocialGroup: saveSelectedSocialGroup,
            removeSelectedSocialGroup: removeSelectedSocialGroup,
            getSelectedSocialGroup: getSelectedSocialGroup,
            getSelectedSocialGroupId: getSelectedSocialGroupId,
            followSocialGroup: followSocialGroup,
            unfollowSocialGroup: unfollowSocialGroup,
            validateSocialGroupId: validateSocialGroupId,
            validateSocialGroupIdFromData: validateSocialGroupIdFromData
        }

        function getGroups(socialModuleToken, setBadges) {
            return $http({
                method: 'get',
                url: '/Social/SocialGroups/' + socialModuleToken,
                params: {
                    setBadges
                }
            }).then(resp => resp.data)
        }

        function saveSelectedSocialGroup(socialModuleToken, socialGroupId) {
            if(typeof(socialGroupId) == typeof undefined){
                removeSelectedSocialGroup(socialModuleToken);
                return;
            }

            var cacheValue = socialGroupId;
            if(cacheValue == null){
                cacheValue = NULL_CACHE_VALUE;
            }

            LocalStorageService.set(getCacheKey(socialModuleToken), cacheValue);
        }

        function removeSelectedSocialGroup(socialModuleToken) {
            LocalStorageService.remove(getCacheKey(socialModuleToken));
        }

        function getSelectedSocialGroupId(socialModuleToken) {
            const value = LocalStorageService.get(getCacheKey(socialModuleToken));

            if(value === NULL_CACHE_VALUE){
                return null;
            }else if(_.isString(value)){
                return parseInt(value);
            }

            return undefined;
        }

        function getSelectedSocialGroup(groups, socialModuleToken) {
            return groups.find(group => group.SocialGroupId === getSelectedSocialGroupId(socialModuleToken))
        }

        function followSocialGroup(socialModuleToken, socialGroupId) {
            return $http.post('/Social/FollowSocialGroup/' + socialModuleToken, {
                socialGroupId: socialGroupId
            })
        }

        function unfollowSocialGroup(socialModuleToken, socialGroupId) {
            return $http.post('/Social/UnfollowSocialGroup/' + socialModuleToken, {
                socialGroupId: socialGroupId
            })
        }

        function validateSocialGroupId(socialModuleToken, socialGroupId, useCache){
            if(useCache){
                const nonCacheValue = validateSocialGroupId(socialModuleToken, socialGroupId, false);
                
                if(typeof(nonCacheValue.socialGroupId) != typeof undefined){
                    return nonCacheValue;
                }

                return {
                    socialGroupId: getSelectedSocialGroupId(socialModuleToken),
                    isCachedValue: true
                };
            }

            if(typeof(socialGroupId) == typeof undefined){
                return {
                    socialGroupId: undefined,
                    isCachedValue: false
                };
            }

            if(_.isString(socialGroupId)){
                if(socialGroupId == ''){
                    return {
                        socialGroupId: undefined,
                        isCachedValue: false
                    };
                }

                return {
                    socialGroupId: parseInt(socialGroupId),
                    isCachedValue: false
                };
            }

            if(_.isNumber(socialGroupId) == false && socialGroupId != null){
                return {
                    socialGroupId: undefined,
                    isCachedValue: false
                };
            }

            return {
                socialGroupId: socialGroupId,
                isCachedValue: false
            };
        }

        function validateSocialGroupIdFromData(socialModuleToken, socialGroups, socialGroupId){
            if(socialGroups && socialGroups.length){
                var socialGroup = socialGroups.find((o) => o.SocialGroupId == socialGroupId);
                if(socialGroup){
                    return socialGroupId;
                }
            }
            
            removeSelectedSocialGroup(socialModuleToken);
            return undefined;
        }

        function getCacheKey(socialModuleToken){
            return CACHE_KEY.replace('{socialModuleToken}', socialModuleToken);
        }
    }
})();
