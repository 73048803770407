(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('badgesList', {
            template: require('./BadgesListComponent.html'),
            controllerAs: 'ctrl',
            controller: [BadgesListController],
            bindings: {
                badge: '<',
                badges: '<',
                hideBadgeDetails: '<'
            }
        });
    
    function BadgesListController() {
        const ctrl = this;
        ctrl.showBadgeDetails = false;
        
        ctrl.onBadgeClick = onBadgeClick;
        ctrl.onBadgeClose = onBadgeClose;
        ctrl.badge = null;
    
    
        function onBadgeClick(badge) {
            ctrl.showBadgeDetails = true;
            ctrl.badge = badge;
        }
    
        function onBadgeClose() {
            ctrl.showBadgeDetails = false;
        }
    }
})();