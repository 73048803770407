(function () {
    'use strict';

    angular.module('App')
        .component('surveyQuestion', {
            template: require('./SurveyQuestionComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$scope', '$location', '$rootScope', '$stateParams', 'TranslationService', 'ToastFactory', SurveyQuestionController],
            bindings: {
                question: '<',
                settings: '<',
                onOptionSelect: '&',
                showAnswers: '<'
            }
        })

    function SurveyQuestionController($scope, $location, $rootScope, $stateParams, TranslationService, ToastFactory) {
        var ctrl = this;

        ctrl.enps = {
            model: 0,
            options: {
                floor: 0,
                ceil: 10,
                showTicks: true,
                hideLimitLabels: true,
                hidePointerLabels: true,
                showSelectionBar: true
            }
        }
        ctrl.$onInit = init;
        ctrl.setAnswer = setAnswer;
        ctrl.pickedOptionsCount = pickedOptionsCount;
        ctrl.toggleTranslation = toggleTranslation;

        function init() {
            if (ctrl.question.SurveyQuestionTypeId === 2) {
                ctrl.isMultiselect = ctrl.question.MaxSelectedOptions > 1;
            }
            if (ctrl.question.SurveyQuestionTypeId === 4) {
                ctrl.question.answered = true;
            }
            if (ctrl.question.SurveyQuestionTypeId === 5) {
                if (ctrl.question.AnswerOptions.some(q => q.picked)) {
                    ctrl.enps.model = ctrl.question.AnswerOptions.find(q => q.picked).Value;
                }

                $scope.$watch('ctrl.enps.model', function (newValue, oldValue) {
                    if (newValue === oldValue || newValue === 0){
                        return;
                    }

                    setAnswer(ctrl.question.AnswerOptions[ctrl.enps.model])
                });
            }

            ctrl.showOriginal = ctrl.question.TranslatableEntity.PreferTranslatedVersion && ctrl.question.TranslatableEntity.LocalizedAlternativeLocale;
            ctrl.question.TranslatableEntity._currentCulture = getCurrentLocale(ctrl.question.TranslatableEntity);
            if (ctrl.question.SurveyQuestionTypeId !== 3) {
                ctrl.question.AnswerOptions.forEach(option => option.TranslatableEntity._currentCulture = getCurrentLocale(option.TranslatableEntity));
            }
        }

        function getCurrentLocale(translatableEntity) {
            return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                translatableEntity.LocalizedAlternativeLocale : translatableEntity.LocalizedLocale;
        }

        function setAnswer(option) {
            _.each(ctrl.question.AnswerOptions, function (opt) {
                if (!ctrl.isMultiselect) {
                    opt.picked = false;
                    opt.marked = false;
                }
            });

            if (ctrl.question.SurveyQuestionTypeId === 3 && ctrl.question.IsRequired) {
                var text = ctrl.question.AnswerText.trim();

                ctrl.question.answered = (text.length > 0);
                option.picked = (text.length > 0);
            } else if (ctrl.question.SurveyQuestionTypeId === 1 || ctrl.question.SurveyQuestionTypeId === 5) {
                ctrl.question.answered = true;
                option.picked = true;

                var markedAll = false;
                _.each(ctrl.question.AnswerOptions, function (opt) {
                    if (!markedAll) {
                        opt.marked = true;
                        markedAll = (opt.SurveyAnswerOptionId === option.SurveyAnswerOptionId);
                    }
                });
            } else {
                if (option.picked) {
                    option.picked = false;
                    option.marked = false;
                    ctrl.question.answered = pickedOptionsCount(ctrl.question.AnswerOptions) > 0
                } else {
                    ctrl.question.answered = true;
                    option.picked = true;
                }
            }

            if (ctrl.isMultiselect) {
                ctrl.disabled = pickedOptionsCount(ctrl.question.AnswerOptions) === ctrl.question.MaxSelectedOptions;
            }

            ctrl.onOptionSelect && ctrl.onOptionSelect();
        }

        function toggleTranslation() {
            if (ctrl.isTranslating) {
                return;
            }

            ctrl.isTranslating = true;

            if (ctrl.question.TranslatableEntity.LocalizedAlternativeLocale &&
                ctrl.question.AnswerOptions.every(option => option.LocalizedAlternativeLocale)) {

                ctrl.showOriginal = !ctrl.showOriginal;
                ctrl.question.TranslatableEntity._currentCulture = ctrl.showOriginal ?
                    ctrl.question.TranslatableEntity.LocalizedAlternativeLocale : ctrl.question.TranslatableEntity.LocalizedLocale;

                if (ctrl.question.SurveyQuestionTypeId !== 3) {
                    ctrl.question.AnswerOptions.forEach(option => option.TranslatableEntity._currentCulture =
                        ctrl.showOriginal && option.TranslatableEntity.LocalizedAlternativeLocale ?
                            option.TranslatableEntity.LocalizedAlternativeLocale : option.TranslatableEntity.LocalizedLocale);
                }
                ctrl.isTranslating = false;
            } else {
                TranslationService.switchTranslatableLocaleSurvey(ctrl.question).then(res => {
                    ctrl.question.TranslatableEntity = res.Question;
                    if (ctrl.question.SurveyQuestionTypeId !== 3) {
                        ctrl.question.AnswerOptions.forEach(option => {
                            const translatedOption = res.AnswerOptions.find(newOption =>
                                newOption.LocalizedLocale.SurveyAnswerOptionId === option.SurveyAnswerOptionId);
                            
                            if (!translatedOption) {
                                return;
                            }
                            
                            option.TranslatableEntity = translatedOption;
                        });
                    }

                    ctrl.showOriginal = !ctrl.showOriginal;
                })
                    .catch(() => ToastFactory.error('ERROR.GENERAL'))
                    .finally(() => ctrl.isTranslating = false);
            }
        }

        function pickedOptionsCount(AnswerOptions) {
            return _.filter(AnswerOptions, {
                picked: true
            }).length
        }
    }
})();