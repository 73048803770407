﻿(function () {
    'use strict';
    var app = angular.module('App.Filters');

    app.filter('htmlFormatText', [
        function () {
            return function (input) {
                var lineBreaksAdded = String(input).replace(/(?:\r\n|\r|\n)/g, '<br />');

                return Autolinker.link(lineBreaksAdded, {
                    stripPrefix: false,
                    replaceFn: function (match) {
                        if (isInternalLink(match)) {
                            const tag = match.buildTag();
                            tag.setAttr('target', '_self');
                            return tag;
                        } 

                        return true; // default tag
                    }
                });

                function isInternalLink(match) {
                    const currentUrl = window.location.href;
                    const matchedUrl = match.getAnchorHref();

                    const currentUrlHostname = new URL(currentUrl).hostname;
                    const matchedUrlHostname = new URL(matchedUrl).hostname;
                    return currentUrlHostname === matchedUrlHostname;
                }
            }
        }
    ]);
})();