﻿(function () {
    'use strict';
    var app = angular.module('App.Filters');

    app.filter('newLineEscapeHtml', [
        function () {
            return function (input) {
                if (input && input.length) {
                    var escaped = _.escape(input);
                    return String(escaped).replace(/(?:\r\n|\r|\n)/g, '<br />');
                }
                return null;
            }
        }
    ]);
})();