﻿(() => {
    'use strict';

    angular
        .module('App.Social')
        .component('hashtagSuggestions', {
            template: require('./HastagSuggestionsComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$timeout', '$scope', 'MentionService', HashtagSuggestionsController],
            bindings: {
                hashtagsSuggestions: '=?',
                onTagSelected: '<',
                text: '<'
            }
        })

    function HashtagSuggestionsController($timeout, $scope, MentionService) {
        const ctrl = this;
        let removeHashtagWatch;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;
        ctrl.tagClick = tagClick;

        function init() {
            ctrl.suggestionsLoading = true;
            if (!ctrl.hashtagsSuggestions?.length) {
                MentionService.getHashTags()
                    .then(hashtags => {
                        ctrl.hashtagsSuggestions = hashtags;
                        watchTag();
                    });
            } else {
                watchTag();
            }
        }

        function watchTag() {
            $timeout(() => {
                ctrl.suggestionsLoading = false;
                removeHashtagWatch = $scope.$watch('ctrl.text', (text) => {
                    ctrl.hashTags = MentionService.sortHashtagsSuggestions(
                        ctrl.hashtagsSuggestions,
                        text
                    );
                })
            })
        }

        function tagClick(name) {
            ctrl.onTagSelected && ctrl.onTagSelected(name)
        }

        function destroy() {
            removeHashtagWatch && removeHashtagWatch();
        }
    }
})();