﻿(function () {
    'use strict';

    var app = angular.module('App.Basics');

    app.constant('events', {
        MENU_UPDATED: 'menu.updated',
        DEPARTMENT_CHANGED: 'department.changed',
        POST_MESSAGE: 'iframe.postmessage',
        PUSH_NEWTOKEN: 'push.newPushToken',
        PUSH_MESSAGE: 'push.messageRecieved',
        BADGE_UPDATED: 'badge.updated',
        APP_HIDE: 'app.hide',
        APP_UNHIDE: 'app.unhide',
        KEYBOARD_WILLHIDE: 'keyboard.willHide',
        KEYBOARD_DIDHIDE: 'keyboard.didHide',
        KEYBOARD_WILLSHOW: 'keyboard.willShow',
        KEYBOARD_DIDSHOW: 'keyboard.didShow',
        PAGE_CHANGED: 'page.changed',
        NOTIFICATIONS_CHANGED: 'notifications.changed',
        PINCODE_DIDSHOW: 'pinCode.didShow',
        PINCODE_DIDHIDE: 'pinCode.didHide',
        USER_LOGGED_IN: 'user.loggedIn',
        USER_LOGGED_OUT: 'user.loggedOut',
        SETTINGS_LOADED: 'settings.loaded'
    });
})();