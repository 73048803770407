﻿(() => {
    'use strict';

    angular
        .module('App.Catalog')
        .component('catalogSearchListComponent', {
            template: require('./CatalogSearchListComponent.html'),
            controllerAs: 'ctrl',
            bindings: {
                items: '<',
                openItem: '<'
            }
        });
})();