﻿'use strict';

angular
    .module('App.Survey')
    .component('questionCommentsComponent', {
        template: require('./QuestionCommentsComponent.html'),
        controllerAs: 'ctrl',
        controller: ['$stateParams', 'SurveyService', 'Page', 'TranslationService', 'HeaderButtonsFactory', QuestionCommentsComponentController],
        bindings: {
            surveyQuestionToken: '<',
            surveyInstanceToken: '<',
            departmentToken: '<',
            filters: '<',
            isDirectReports: '<',
            surveyData: '<'
        }
    })

function QuestionCommentsComponentController($stateParams, SurveyService, Page, TranslationService, HeaderButtonsFactory) {
    const ctrl = this;

    ctrl.surveyComments = [];
    ctrl.sentimentalTabFilter = 'all';
    ctrl.SENTIMENT_ICONS = SurveyService.SENTIMENT_ICONS;

    ctrl.$onInit = init;
    ctrl.$onDestroy = destroy;
    ctrl.openNextPrevQuestion = openNextPrevQuestion;
    ctrl.setSentimentalTabFilter = setSentimentalTabFilter;
    ctrl.filterSentimentalComments = filterSentimentalComments;
    ctrl.getCommentsBySentiment = getCommentsBySentiment;

    ctrl.sentimentMapper = {
        positive: 0,
        neutral: 1,
        negative: 2,
        mixed: 3,
        na: 4
    };

    function init() {
        ctrl.isLoaded = false;
        return SurveyService.getQuestionComments(ctrl.surveyQuestionToken, ctrl.surveyInstanceToken, ctrl.departmentToken, ctrl.filters?.UserGroupIds, ctrl.isDirectReports)
            .then(resp => {
                ctrl.surveyToken = resp.SurveyToken;
                ctrl.nextQuestionToken = resp.NextQuestionToken;
                ctrl.previousQuestionToken = resp.PreviousQuestionToken;
                ctrl.currentQuestionIndex = resp.CurrentQuestionIndex;
                ctrl.totalQuestionsCount = resp.TotalCount;

                ctrl.surveyComments = resp.Comments;
                ctrl.filteredComments = ctrl.surveyComments;
                ctrl.question = TranslationService.getCurrentLocale(resp.TranslatableQuestionEntity).QuestionText;
                ctrl.sortOrder = resp.SortOrder;
                ctrl.sentimentEnabled = resp.SentimentEnabled;

                ctrl.userGroups = resp.UserGroups || [];
                SurveyService.setFilters(openFilters, ctrl.filters);
            })
            .finally(() => {
                ctrl.isLoaded = true;
            })
    }

    function openFilters() {
        SurveyService.openFiltersPopup({
            userGroups: ctrl.userGroups,
            model: ctrl.filters,
            onFiltersApply: (filters) => {
                ctrl.filters = filters || [];
                ctrl.isLoaded = false;
                init().then(() => {
                    setSentimentalTabFilter(ctrl.sentimentalTabFilter);
                });
                SurveyService.setFilters(openFilters, ctrl.filters);
            }
        });
    }

    function openNextPrevQuestion(isNext) {
        const questionToken = isNext ? ctrl.nextQuestionToken : ctrl.previousQuestionToken;
        Page.stateGo('questionComments', questionToken, ctrl.surveyInstanceToken, {...ctrl.surveyData}, true, {
            departmentToken: ctrl.departmentToken,
            expired: $stateParams.expired
        });
    }

    function setSentimentalTabFilter(sentiment) {
        ctrl.sentimentalTabFilter = sentiment;
        ctrl.filterSentimentalComments(sentiment);
        scrollTop();
    }

    function filterSentimentalComments(sentiment) {
        if (sentiment === 'all') {
            ctrl.filteredComments = ctrl.surveyComments;
        } else {
            ctrl.filteredComments = ctrl.surveyComments.filter(comment => comment.SentimentType === ctrl.sentimentMapper[sentiment]);
        }
    }

    function getCommentsBySentiment(sentiment) {
        return ctrl.surveyComments.filter(comment => comment.SentimentType === ctrl.sentimentMapper[sentiment]);
    }

    function scrollTop() {
        document.querySelector('html, body').scrollTop = 0;

    }

    function destroy() {
        HeaderButtonsFactory.resetButtonsList();
    }
}
