﻿(() => {
    'use strict';

    angular
        .module(
            'App.Social',
            ['App.Common', 'App.Basics']
        );

    require([
        './SocialRoutes',
        './SocialService',
        './SocialComponent',
        
        './SocialWall/SocialController',
        './SocialWall/SocialWallComponent',
        './SocialWall/Services/SocialWallService',
        './SocialWall/Services/SocialSortingService',
        
        './SocialWall/Create/SocialPostCreateComponent',
        './SocialWall/Create/SocialPostCreateService',
        
        './SocialWall/Hashtags/SocialHashtagsComponent',
        './SocialWall/Hashtags/SocialHashTagsSearchController',
        
        './SocialWall/Post/SocialPost.Directive',
        './SocialWall/Post/SocialPostController',
        
        './SocialWall/Post/Poll/SocialPollComponent',
        './SocialWall/Post/Poll/SocialPollService',
        
        './SocialWall/Post/Poll/VotersPopup/VotersPopupComponent',
        './SocialWall/Post/Poll/VotersPopup/VotersPopupService',
        
        './SocialWall/Post/SocialCommentsPopup/SocialCommentsPopupComponent',
        
        './SocialWall/HastagSuggestions/HastagSuggestionsComponent',
        
        './Groups/SocialOverviewController',
        './Groups/SocialGroupsService',
        './Groups/SocialGroupsComponent',
        './Groups/Group/SocialGroupComponent',
        
        './LiveTile/SocialLiveTileComponent',
    ])
})();