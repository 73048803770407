﻿angular
    .module('App.Survey')
    .component('surveyManagerTab', {
        template: require('./SurveyManagerTabComponent.html'),
        controllerAs: 'ctrl',
        controller: ['$stateParams', '$scope', '$element', '$timeout', 'Page', 'SurveyService', surveyManagerTabController],
        bindings: {
            surveyToken: '<',
            departmentToken: '<',
            managerViewSettings: '<',
            isSingleDepartment: '<',
            isDirectReports: '<',
            isVisible: '<',
            surveyData: '<',
            filters: '<'
        }
    })

function surveyManagerTabController($stateParams, $scope, $element, $timeout, Page, SurveyService) {
    const ctrl = this;

    ctrl.$onInit = init;
    ctrl.$onChanges = onChange;
    ctrl.$onDestroy = destroy;
    ctrl.openDirectReports = openDirectReports;

    const unsubscribeFiltersUpdate = $scope.$on('SURVEY_FILTERS.UPDATED', () => {
        ctrl.isLoaded = false;
        loadData(true);
    });
    
    function init() {
        if (ctrl.surveyData.ManagerViewIsSingleDepartment) {
            ctrl.isSingleDepartment = true;
        }
    }

    function onChange(changesObj) {
        if (changesObj?.isVisible) {
            toggleTabClass(changesObj?.isVisible?.currentValue);

            if (!changesObj?.isVisible?.currentValue) {
                return false
            }

            if (ctrl.isDirectReports) {
                Page.setSubTitle('SURVEY.MANAGER_VIEW.DIRECT_REPORTS');
            }

            loadData();
        }
    }

    function loadData(forceLoad) {
        if (forceLoad || !ctrl.isLoaded) {
            SurveyService.getManagerTabData(ctrl.surveyToken, ctrl.surveyData.SurveyInstanceToken, ctrl.departmentToken, ctrl.filters?.UserGroupIds, ctrl.isDirectReports)
                .then(data => {
                    ctrl.participationRate = data.ParticipationRate;

                    ctrl.overallResultValueString = data.OverallResultValueString ?? 'N/A';
                    ctrl.overallResult = data.OverallResult;
                    ctrl.rateType = data.RateType;

                    ctrl.directReportOverallResult = data.DirectReportOverallResult;
                    ctrl.directReportOverallResultValueString = data.DirectReportOverallResultValueString;
                    ctrl.directReportRateType = data.DirectReportRateType;

                    ctrl.departmentInsights = data.DepartmentInsights;
                    ctrl.departmentCount = data.DepartmentCount;
                    ctrl.departmentToken = data.DepartmentToken;

                    ctrl.questionInsights = data.QuestionInsights;

                    if (data.DepartmentName) {
                        Page.setSubTitle(data.DepartmentName);
                    }

                    if (ctrl.managerViewSettings.MonitorDataCollectionAccess) {
                        $timeout(() => initPartitionRateChart());
                    }
                })
                .finally(() => {
                    ctrl.isLoaded = true;
                });
        }
    }

    function openDirectReports() {
        Page.stateGo('directReports', ctrl.surveyToken, null, null, {
            ...ctrl.surveyData,
            filters: ctrl.filters
        }, true, {expired: $stateParams.expired});
    }

    function initPartitionRateChart() {
        SurveyService.initParticipationRateChart($element.find('.chart-container')[0], ctrl.participationRate)
    }

    function toggleTabClass(isVisible) {
        $timeout(() => {
            document.querySelector('.base-view').classList.toggle('base-view-survey-manager-tab', isVisible);
        })
    }

    function destroy() {
        unsubscribeFiltersUpdate && unsubscribeFiltersUpdate();
    }
}
