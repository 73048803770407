﻿(function () {
    'use strict';
    var app = angular.module('App.Filters');

    app.filter('newLineWords', [
        function () {
            return function (input) {
                return String(input).replace(' ', '<br /> ');
            }
        }
    ]);
})();