﻿(function () {
    'use strict';
    var app = angular.module('App.Filters');

    app.filter('newLine', [
        function () {
            return function (input) {
                if (input && input.length)
                    return String(input).replace(/(?:\r\n|\r|\n)/g, '<br />');
                else
                    return null;
            }
        }
    ]);
})();