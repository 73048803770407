﻿(() => {
    'use strict';

    angular.module('App.Common')
        .service('LazyInitWrapperService', [function () {
            let observer, callbacksToInit = [];

            return {
                initObserver: initObserver,
                observe: observe,
                unobserve: unobserve
            }

            function initObserver() {
                observer = new IntersectionObserver((entries, observer) => {
                    const visibleItems = entries.filter(item => item.isIntersecting);
                    visibleItems.forEach(visibleItem => {
                        const index = callbacksToInit.findIndex(item => item.element === visibleItem.target)
                        if (index !== -1) {
                            callbacksToInit[index].initCallback();
                            observer.unobserve(callbacksToInit[index].element)
                            callbacksToInit.splice(index, 1);
                        }
                    });
                }, {threshold: 0.2})
            }

            function observe(element, initCallback) {
                observer.observe(element)
                callbacksToInit.push({element, initCallback});
            }

            function unobserve(element) {
                const index = callbacksToInit.findIndex(item => item.element === element)
                if (index !== -1) {
                    observer.unobserve(callbacksToInit[index].element)
                    callbacksToInit.splice(index, 1);
                }
            }
        }])
})();