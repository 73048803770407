﻿(() => {
    'use strict';

    angular
        .module('App.Survey')
        .component('surveyPage', {
            template: require('./SurveyPageComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$timeout', '$rootScope', '$stateParams', 'ResponsiveService', 'SurveyService', 'HeaderButtonsFactory', SurveyPageController],
            bindings: {
                surveyData: '<',
                departmentToken: '<',
                isSingleDepartment: '<',
                isDirectReports: '<',
                expired: '<'
            }
        })

    function SurveyPageController($timeout, $rootScope, $stateParams, ResponsiveService, SurveyService, HeaderButtonsFactory) {
        const ctrl = this;

        ctrl.surveyData.filters = ctrl.surveyData.filters || {};
        ctrl.TABS = SurveyService.SURVEY_PAGE_TABS;
        ctrl.isDesktop = ResponsiveService.isDesktop();

        ctrl.$onInit = init;
        ctrl.changeTab = changeTab;
        ctrl.openFilters = openFilters;
        ctrl.$onDestroy = destroy;

        function init() {
            $rootScope.$broadcast('getSurveyToken', ctrl.surveyData.SurveyToken);

            ctrl.surveyAvaliable = (!ctrl.expired && !ctrl.isSingleDepartment && !ctrl.isDirectReports);
            ctrl.managerViewAvailable = ctrl.surveyData.ManagerViewSettings.IsManagerViewAvailable;
            ctrl.commentsEnabled = ctrl.surveyData.CommentsEnabled;
            ctrl.isSurveyAnswered = ctrl.surveyAvaliable && ctrl.surveyData.Survey.IsAnswered;

            ctrl.activeTab = getActiveTab();

            ctrl.isTabsAvaliable = [ctrl.surveyAvaliable, ctrl.managerViewAvailable, ctrl.commentsEnabled]
                .filter(item => item === true)?.length > 1;

            if (ctrl.isTabsAvaliable && !ctrl.isDesktop) {
                ctrl.showTabs = true;
            }

            SurveyService.setHeaderButtons(ctrl);
        }

        function getActiveTab() {
            if ($stateParams.tab) {
                if (ctrl.managerViewAvailable && $stateParams.tab === 'manager') {
                    return ctrl.TABS.MANAGER_VIEW;
                }
                if (ctrl.commentsEnabled && $stateParams.tab === 'comments') {
                    return ctrl.TABS.COMMENTS;
                }
            }

            if (ctrl.surveyAvaliable && !ctrl.surveyData.Survey.IsAnswered) {
                return ctrl.TABS.SURVEY;
            }

            if (ctrl.managerViewAvailable) {
                return ctrl.TABS.MANAGER_VIEW;
            } else if (ctrl.commentsEnabled) {
                return ctrl.TABS.COMMENTS;
            }

            return ctrl.TABS.SURVEY;
        }

        function changeTab(tab) {
            ctrl.activeTab = tab;
            SurveyService.changeTab(ctrl);
        }

        function destroy() {
            HeaderButtonsFactory.resetButtonsList();
        }

        function openFilters() {
            SurveyService.openFiltersPopup({
                userGroups: ctrl.surveyData.ManagerViewSettings.UserGroups,
                model: ctrl.surveyData.filters,
                onFiltersApply: (filters) => {
                    ctrl.surveyData.filters = filters || [];
                    $timeout(() => {
                        $rootScope.$broadcast('SURVEY_FILTERS.UPDATED');
                    })
                    SurveyService.setHeaderButtons(ctrl);
                }
            });
        }
    }
})();