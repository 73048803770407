﻿const {transliterate} = require('transliteration');

(() => {
    'use strict';

    angular
        .module('App.Social')
        .directive('socialPost', ['ModalService', 'ProfileViewerService', '$http', 'ActionSheetService', '$translate',
            'Page', '$parse', 'NotificationFactory', 'BasicHelper', 'Profile', 'REACTION_TYPE_ID', '$timeout',
            '$filter', 'ConfirmPopupService', 'PopupWrapperService', 'ToastFactory', 'CopyLinkService', 'TranslationService',
            'ReportPopupService', 'BookmarkService',
            function (ModalService, ProfileViewerService, $http, ActionSheetService, $translate, Page, $parse,
                NotificationFactory, BasicHelper, Profile, REACTION_TYPE_ID, $timeout, $filter,
                ConfirmPopupService, PopupWrapperService, ToastFactory, CopyLinkService, TranslationService, ReportPopupService, BookmarkService) {
                return {
                    restrict: 'E',
                    template: require('./SocialPost.Directive.tpl.html'),
                    scope: {
                        post: '=',
                        settings: '=',
                        deleted: '&',
                        postPinned: '&',
                        edit: '<',
                        openReactionsViewer: '<',
                        isPinEnabled: '<',
                        isHashtag: '<',
                        openComments: '=?',
                        moduleToken: '@'
                    },
                    controller: ['$element', '$scope', '$rootScope',
                        function ($element, $scope, $rootScope) {
                            // Initial values
                            $scope.isBookmarkUpdating = false,
                            $scope.showBookmarkMessage = false;
                            $scope.showHint = false;
                            $scope.showReactionsMessage = false;

                            // Bindings
                            $scope.pinPost = pinPost;
                            $scope.openComments = openCommentsPopup;
                            $scope.showReactionsViewer = showReactionsViewer;
                            $scope.showOptions = showOptions;
                            $scope.sendReaction = sendReaction;
                            $scope.like = like;
                            $scope.removeReaction = removeReaction;
                            $scope.onPostClick = onPostClick;
                            $scope.onToggleReactionsList = onToggleReactionsList;
                            $scope.openProfile = openProfile;
                            $scope.toggleTranslation = toggleTranslation;
                            $scope.selectCurrentTranslatedText = selectCurrentTranslatedText;
                            $scope.getPollTitle = getPollTitle;
                            $scope.isTranslationAllowed = isTranslationAllowed;
                            $scope.BookmarkService = BookmarkService;

                            $scope.openBookmarks = function (e) {
                                e && e.stopPropagation();
                                Page.stateGo('bookmarks');
                                return false;
                            };
                            
                            initDirective();

                            function initDirective() {
                                $scope.showOriginal = true;
                                $scope.stamp = getStamp($scope.post.Hashtags);

                                getCurrentLocale();

                                $scope.shouldBeExcluded = $scope.post.ShouldBeExcluded;

                                if ($scope.post.TranslatableEntity) {
                                    $scope.post.SourceText = selectCurrentTranslatedText($scope.post.TranslatableEntity);
                                }

                                isTranslationAllowed() && getTranslationState();

                                $rootScope.$on('social:postUpdated', (ev, updatedPost) => {
                                    if (updatedPost?.SocialPostToken === $scope.post.SocialPostToken) {
                                        getCurrentLocale();
                                        getTranslationState();
                                    }
                                })
                            }

                            function getCurrentLocale() {
                                if ($scope.post.TranslatableEntity) {
                                    $scope.post.TranslatableEntity._currentCultureId = null;
                                    $scope.post.TranslatableEntity._currentCulture = selectCurrentLocale($scope.post.TranslatableEntity);
                                }

                                if ($scope.post.SocialGroupTranslatableEntity) {
                                    $scope.post.SocialGroupTranslatableEntity._currentCultureId = null;
                                    $scope.post.SocialGroupTranslatableEntity._currentCulture = selectCurrentLocale($scope.post.SocialGroupTranslatableEntity);
                                }

                                if ($scope.post.PollData?.TranslatableEntity) {
                                    $scope.post.PollData.TranslatableEntity._currentCultureId = null;
                                    $scope.post.PollData.TranslatableEntity._currentCulture = selectCurrentLocale($scope.post.PollData.TranslatableEntity);

                                    $scope.post.PollData.PollOptions = $scope.post.PollData.PollOptions?.length &&
                                        $scope.post.PollData.PollOptions.map(option => setPollCurrentLocale(option));
                                }
                            }

                            function getTranslationState() {
                                $scope.showOriginal = true;

                                if ($scope.post.TranslatableEntity) {
                                    $scope.showOriginal = !($scope.post.TranslatableEntity.PreferTranslatedVersion && $scope.post.TranslatableEntity.LocalizedAlternativeLocale);
                                }

                                if ($scope.post.SocialGroupTranslatableEntity) {
                                    const socialTranslation = $scope.post.SocialGroupTranslatableEntity;
                                    $scope.showOriginal = $scope.showOriginal &&
                                        !(socialTranslation.PreferTranslatedVersion && socialTranslation.LocalizedAlternativeLocale);
                                }

                                if ($scope.post.PollData?.TranslatableEntity) {
                                    const pollTranslation = $scope.post.PollData.TranslatableEntity;
                                    $scope.showOriginal = $scope.showOriginal && !(pollTranslation.PreferTranslatedVersion && pollTranslation.LocalizedAlternativeLocale);
                                }
                            }

                            function isTranslationAllowed() {
                                let isAllowed;
                                if ($scope.post.TranslatableEntity) {
                                    isAllowed = $scope.post.TranslatableEntity.AllowAutoTranslation ||
                                        (!$scope.post.TranslatableEntity.AllowAutoTranslation && $scope.post.TranslatableEntity.LocalizedAlternativeLocale);
                                }

                                if ($scope.post.SocialGroupTranslatableEntity) {
                                    isAllowed = isAllowed || ($scope.post.SocialGroupTranslatableEntity.AllowAutoTranslation ||
                                        (!$scope.post.SocialGroupTranslatableEntity.AllowAutoTranslation &&
                                            $scope.post.SocialGroupTranslatableEntity.LocalizedAlternativeLocale));
                                }

                                if ($scope.post.PollData?.TranslatableEntity) {
                                    isAllowed = isAllowed || ($scope.post.PollData.TranslatableEntity.AllowAutoTranslation ||
                                        (!$scope.post.PollData.TranslatableEntity.AllowAutoTranslation &&
                                            $scope.post.PollData.TranslatableEntity.LocalizedAlternativeLocale));
                                }

                                return isAllowed;
                            }

                            function getPollTitle() {
                                if ($scope.post.PollData?.TranslatableEntity) {
                                    return $scope.post.PollData.TranslatableEntity._currentCulture?.QuestionText ||
                                        selectCurrentLocale($scope.post.PollData.TranslatableEntity).QuestionText
                                }
                            }

                            function selectCurrentTranslatedText(translatableEntity) {
                                return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                                    translatableEntity.LocalizedAlternativeLocale.Text : translatableEntity.LocalizedLocale.Text
                            }

                            function selectCurrentLocale(translatableEntity) {
                                return translatableEntity.PreferTranslatedVersion && translatableEntity.LocalizedAlternativeLocale ?
                                    translatableEntity.LocalizedAlternativeLocale : translatableEntity.LocalizedLocale
                            }
                            
                            function setPollCurrentLocale(poll) {
                               if (poll.TranslatableEntity) {
                                   poll.TranslatableEntity._currentCulture = selectCurrentLocale(poll.TranslatableEntity);
                                   poll.TranslatableEntity._currentCultureId = null;
                               }
                                
                                return poll;
                            }

                            function getStamp(hashtags) {
                                const stamps = hashtags.filter(hashtag => hashtag.StampMedia);

                                if (stamps?.length) {
                                    const stampMedia = stamps[0].StampMedia;
                                    const stampName = stamps[0].Name;
                                    let stampCssClassName = '';

                                    if (stampName?.length) {
                                        try {
                                            stampCssClassName = 'hashtag-' + transliterate(stampName).toLowerCase();
                                        } catch (err) {
                                            stampCssClassName = 'hashtag-' + stampName.toLowerCase();
                                            console.error(err);
                                        }
                                    }

                                    return {
                                        StampMedia: stampMedia,
                                        Name: stampName,
                                        CssClassName: stampCssClassName
                                    }
                                }
                            }

                            function openProfile(e) {
                                e.stopPropagation();
                                ProfileViewerService.showProfileCard($scope.post.CreatedByUserToken);
                            }

                            // Methods
                            function like() {
                                if (!$scope.post.ReactionTypeId) {
                                    sendReaction(REACTION_TYPE_ID.LIKE).then(function () {
                                        $scope.post.ReactionTypeId = REACTION_TYPE_ID.LIKE;
                                        $scope.post.TotalReactions++;
                                    });
                                } else {
                                    removeReaction().then(function () {
                                        $scope.post.ReactionTypeId = null;
                                        $scope.post.TotalReactions--;
                                    });
                                }
                            }

                            function sendReaction(typeId) {
                                return $http.post('/Social/Reaction/', {
                                    SocialPostToken: $scope.post.SocialPostToken,
                                    ReactionTypeId: typeId
                                });
                            }

                            function removeReaction() {
                                return $http.post('/Social/RemoveReaction/' + $scope.post.SocialPostToken);
                            }

                            function openCommentsPopup(commentId) {
                                if ($scope.settings.AllowComments) {
                                    PopupWrapperService.createDynamic(
                                        `<social-comments-popup
                                                        post="post"
                                                        settings="settings"
                                                        comment-id="commentId"
                                                        module-token="{{ moduleToken }}"
                                                    ></social-comments-popup>`,
                                        {
                                            commentId: commentId,
                                            post: $scope.post,
                                            settings: $scope.settings,
                                            moduleToken: $scope.moduleToken
                                        }
                                    )
                                }

                            }

                            $scope.ToggleBookmark = function () {
                                if (!$scope.isBookmarkUpdating) {
                                    if ($scope.post.BookmarkId) {
                                        // Delete
                                        $scope.isBookmarkUpdating = true;
                                        BookmarkService.deleteBookmark($scope.post.BookmarkId).then(function (success) {
                                            if (success) {
                                                $scope.isBookmarkUpdating = false;
                                                $scope.post.BookmarkId = null;
                                            }
                                        });
                                    } else {
                                        // Create
                                        $scope.isBookmarkUpdating = true;
                                        BookmarkService.createSocialBookmark($scope.post.SocialPostToken).then(function (bookmarkId) {
                                            if (bookmarkId) {
                                                $scope.isBookmarkUpdating = false;
                                                $scope.post.BookmarkId = bookmarkId;
                                                showBookmarkMessage();
                                            }
                                        });
                                    }
                                }
                            };

                            function showBookmarkMessage() {
                                $scope.showBookmarkMessage = true;
        
                                $timeout(function () {
                                    $scope.showBookmarkMessage = false
                                }, 5000)
                            }

                            function showReactionsViewer() {
                                if ($scope.settings.AllowReactions) {
                                    $scope.openReactionsViewer($scope.post.SocialPostToken);
                                }
                            }

                            function toggleSubscribtion() {
                                if ($scope.post.IsSubscribed) {
                                    $http.post('/Social/UnSubscribe/' + $scope.post.SocialPostToken);
                                } else {
                                    $http.post('/Social/Subscribe/' + $scope.post.SocialPostToken);
                                }

                                $scope.post.IsSubscribed = !$scope.post.IsSubscribed;
                            }

                            function onReportSubmit(model) {
                                return ReportPopupService.reportSocialPost({
                                    token: $scope.post.SocialPostToken,
                                    model,
                                })
                            }

                            function onCloseReport(submitted) {
                                ReportPopupService.onReportSubmit(submitted);
                            }

                            function reportPost() {
                                ReportPopupService.openReportPopup(onCloseReport, onReportSubmit);
                            }

                            function showOptions(event) {
                                event.stopPropagation();
                                var buttons = [];
                                if ($scope.settings.PinnedPostsEnabled && $scope.isPinEnabled) {
                                    buttons.push({
                                        text: $scope.post.Pinned ? 'SOCIAL.UNPIN' : 'SOCIAL.PIN',
                                        icon: 'pin',
                                        iconClass: $scope.post.Pinned ? 'label-color active' : 'label-color',
                                        prefix: $scope.post.Pinned ? 'fas' : 'fal',
                                        onClick: function () {
                                            pinPost();
                                        }
                                    })
                                }

                                buttons.push({
                                    text: ($scope.post.IsSubscribed) ? 'SOCIAL.UNSUBSCRIBE' : 'SOCIAL.SUBSCRIBE',
                                    icon: 'notifications',
                                    prefix: ($scope.post.IsSubscribed) ? 'fas' : 'fal',
                                    iconClass: $scope.post.IsSubscribed ? 'label-color active' : 'label-color',
                                    onClick: function () {
                                        toggleSubscribtion();
                                    }
                                });

                                //Copy link
                                buttons.push({
                                    text: 'COPY_LINK',
                                    icon: 'link',
                                    onClick: function () {
                                        copySocialLink();
                                    }
                                });

                                // Edit
                                if ($scope.post.IsEditable && !$scope.isHashtag) {
                                    buttons.push({
                                        text: 'SOCIAL.EDIT',
                                        icon: 'edit',
                                        onClick: editPost
                                    });
                                }

                                // Report
                                if (Page.getSettings()?.ContentReportingEnabled) {
                                    buttons.push({
                                        text: 'REPORT.SOCIAL_POST.BUTTON',
                                        icon: 'exclamation-circle',
                                        onClick: function () {
                                            reportPost();
                                        }
                                    });
                                }

                                // Delete
                                if ($scope.post.IsDeleteable) {
                                    buttons.push({
                                        text: 'SOCIAL.DELETE',
                                        icon: 'delete',
                                        iconClass: 'red label-color',
                                        onClick: function () {
                                            deletePost();
                                        }
                                    });
                                }

                                // Close
                                var actionSheet = ActionSheetService.create(buttons, event.currentTarget, null, {
                                    name: 'social-post',
                                    token: $scope.moduleToken || $scope.settings.AccountModuleToken
                                });
                                actionSheet.show();
                            }

                            function deletePost() {
                                var opts = {
                                    message: $filter('translate')('SOCIAL.CONFIRM_DELETE')
                                };

                                ConfirmPopupService.open(opts).then(function () {
                                    // Confirmed
                                    $http.post('/Social/DeletePost/' + $scope.post.SocialPostToken);

                                    if ($scope.deleted) {
                                        $parse($scope.deleted)({
                                            $post: $scope.post
                                        });
                                    }
                                });
                            }

                            function editPost() {
                                if ($scope.edit) {
                                    $scope.edit($scope.post);
                                }
                            }

                            function copySocialLink() {
                                CopyLinkService.copy(
                                    '/Social/GenerateLink/' + $scope.post.SocialPostToken,
                                    $element.find('.app-link-url')
                                )
                            }

                            function onPostClick(e) {
                                e.stopPropagation();
                                Page.stateGo('socialPost', $scope.post.SocialPostToken + '?accountModuleToken=' + $scope.post.AccountModuleToken, '');
                            }

                            function pinPost() {
                                var action = $scope.post.Pinned ? 'UnPin' : 'Pin';

                                $http.post('/Social/' + action + '/' + $scope.post.SocialPostToken).then(function (data) {
                                    $scope.post.Pinned = !$scope.post.Pinned;
                                    $scope.postPinned && $scope.postPinned();
                                });
                            }

                            function onToggleReactionsList(state) {
                                $scope.showReactionsMessage = state;
                            }

                            function toggleTranslation() {
                                if ($scope.isTranslating) {
                                    return;
                                }

                                $scope.isTranslating = true;
                                const translatableEntities = $scope.post.PollData?.TranslatableEntity && $scope.post.PollData.PollOptions?.length ? {
                                    Post: $scope.post.TranslatableEntity,
                                    Poll: $scope.post.PollData.TranslatableEntity,
                                    PollOptions: $scope.post.PollData.PollOptions.map(option => option.TranslatableEntity),
                                    Group: $scope.post.SocialGroupTranslatableEntity
                                } : {
                                    Post: $scope.post.TranslatableEntity,
                                    Group: $scope.post.SocialGroupTranslatableEntity
                                }

                                TranslationService.switchTranslatableLocaleSocial(translatableEntities, $scope.post.SocialPostToken).then(res => {
                                    $scope.post.TranslatableEntity = res.Post && res.Post;
                                    $scope.post.SocialGroupTranslatableEntity = res.Group && res.Group;

                                    if ($scope.post.PollData) {
                                        $scope.post.PollData.TranslatableEntity = res.Poll && res.Poll;
                                    }

                                    if ($scope.post.PollData?.PollOptions?.length) {
                                        $scope.post.PollData.PollOptions = res.PollOptions?.length &&
                                            $scope.post.PollData.PollOptions.map(option => ({
                                                ...option,
                                                TranslatableEntity: res.PollOptions.find(localeOption =>
                                                    localeOption.LocalizedLocale.SurveyAnswerOptionId === option.PollOptionId)
                                            }));
                                    }
                                    $scope.showOriginal = !$scope.showOriginal;
                                })
                                    .catch(() => ToastFactory.error('ERROR.GENERAL'))
                                    .finally(() => $scope.isTranslating = false);

                            }
                        }]
                };
            }
        ]);
})();
