﻿(function () {
    'use strict';

    angular
        .module('App.Filters')
        .filter('cut', [
            function () {
                return function (value, wordwise, max, cutHtmlTags, tail) {
                    if (!value) return '';
                    max = parseInt(max, 10);
                    if (!max) return value;
                    if (value.length <= max) return value;

                    if (cutHtmlTags) {
                        const parse = new DOMParser().parseFromString(value, 'text/html');
                        value = parse.body.textContent || "";
                        //remove text from froala video tag
                        value = value.replaceAll('Your browser does not support HTML5 video.', '');
                    }

                    value = value.substring(0, max);
                    if (wordwise) {
                        var lastspace = value.lastIndexOf(' ');
                        if (lastspace !== -1) {
                            //Also remove . and , so its gives a cleaner result.
                            if (value.charAt(lastspace - 1) === '.' || value.charAt(lastspace - 1) === ',') {
                                lastspace = lastspace - 1;
                            }
                            value = value.substring(0, lastspace);
                        }
                    }

                    return value + (tail || '…');
                };
            }
        ]);
})();