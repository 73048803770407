﻿(() => {
    'use strict'

    angular
        .module('App.Training')
        .component('lessonIntroduction', {
            template: require('./LessonIntroductionComponent.html'),
            controllerAs: 'ctrl',
            controller: ['LESSON_STATE', 'TrainingService', LessonIntroductionController],
            bindings: {
                lesson: '<',
                onGoToQuestions: '<',
                onFinishLesson: '<',
                onRedo: '<',
                onSeeResults: '<',
                onGoToNextLesson: '<',
                isLastLesson: '<',
                emptyLesson: '<',
                isLoading: '<'
            }
        })

    function LessonIntroductionController(LESSON_STATE, TrainingService) {
        const ctrl = this;

        ctrl.LESSON_STATE = LESSON_STATE;
        ctrl.getTime = TrainingService.getDurationString;

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;

        function init() {
            ctrl.canRedo = !ctrl.lesson.Expired && ctrl.lesson.Action === 3;
        }

        function destroy() {
            document.querySelector('lesson-introduction').classList.add('hidden');
        }
    }
})();