﻿var NativeBridgeCallback = NativeBridgeCallback || {};
var NativeReactor = NativeReactor || new Reactor();

/* Native Bridge
----------------------------------------- */
// NativeBridge Basics
NativeBridgeCallback.Methods = [{
    Method: { MethodName: 'availableMethods', Area: 'basic' },
    Parameters: []
}];
NativeBridgeCallback.hasMethod = function (methodName, area, requireParameter) {
    if (_.isBoolean(requireParameter) === false) {
        requireParameter = false;
    }

    if (_.isArray(NativeBridgeCallback.Methods) && !_.isEmpty(NativeBridgeCallback.Methods)) {
        var method = _.find(NativeBridgeCallback.Methods, function (o) {
            return o.Method.MethodName.toLowerCase() === methodName.toLowerCase()
                && o.Method.Area.toLowerCase() === area.toLowerCase()
                && (requireParameter === false || (requireParameter === true && _.isArray(o.Parameters) && o.Parameters.length > 0));
        });
        return _.isObject(method);
    }
    return false;
};
NativeBridgeCallback.parseJSON = function (data) {
    if (_.isString(data) && (data.startsWith('{') || data.startsWith('['))) {
        return JSON.parse(data.replace(/\r/g, '\\r').replace(/\n/g, '\\n'));
    }
    return data;
};
NativeBridgeCallback.parseBoolean = function (data) {
    if (_.isString(data)) {
        return data.toLowerCase() === 'true';
    }
    return data;
};

/* Native Bridge Callbacks
----------------------------------------- */
// Basic
NativeReactor.registerEvent('native.basic.getAvailableMethods');
NativeReactor.registerEvent('native.basic.getAppInfo');
NativeReactor.registerEvent('native.basic.appIsReady');
NativeReactor.registerEvent('native.basic.onAppResume');
NativeReactor.registerEvent('native.basic.onAppPause');

NativeBridgeCallback.basic = {
    getAvailableMethods: function (data) {
        data = NativeBridgeCallback.parseJSON(data);
        NativeBridgeCallback.Methods = data;
        NativeReactor.dispatchEvent('native.basic.getAvailableMethods', data);
    },
    getAppInfo: function (data) {
        data = NativeBridgeCallback.parseJSON(data);
        NativeReactor.dispatchEvent('native.basic.getAppInfo', data);
    },
    onAppResume: function (data) {
        if (data && data.startsWith('{')) {
            data = NativeBridgeCallback.parseJSON(data);
        }
        NativeReactor.dispatchEvent('native.basic.onAppResume', data);
    },
    onAppPause: function (data) {
        NativeReactor.dispatchEvent('native.basic.onAppPause', data);
    }
}

// User
NativeReactor.registerEvent('native.user.getLoginStatus');

NativeBridgeCallback.user = {
    getLoginStatus: function (data) {
        data = NativeBridgeCallback.parseBoolean(data);
        NativeReactor.dispatchEvent('native.user.getLoginStatus', data);
    }
};

// Biometric
NativeReactor.registerEvent('native.biometric.getStatus');
NativeReactor.registerEvent('native.biometric.getPin');
NativeReactor.registerEvent('native.biometric.savePin');
NativeReactor.registerEvent('native.biometric.clearPin');

NativeBridgeCallback.biometric = {
    getStatus: function(data) {
        data = NativeBridgeCallback.parseJSON(data);
        NativeReactor.dispatchEvent('native.biometric.getStatus', data);
    },
    getPin: function(data){
        data = NativeBridgeCallback.parseJSON(data);
        NativeReactor.dispatchEvent('native.biometric.getPin', data);
    },
    savePin: function(data){
        data = NativeBridgeCallback.parseJSON(data);
        NativeReactor.dispatchEvent('native.biometric.savePin', data);
    },
    clearPin: function(data){
        data = NativeBridgeCallback.parseJSON(data);
        NativeReactor.dispatchEvent('native.biometric.clearPin', data);
    }
};

// Push
NativeReactor.registerEvent('native.push.newPushToken');
NativeReactor.registerEvent('native.push.getToken');
NativeReactor.registerEvent('native.push.messageRecieved');

NativeBridgeCallback.push = {
    newPushToken: function (data) {
        var jsonParsed = NativeBridgeCallback.parseJSON(data);
        if (_.isString(jsonParsed)) {
            // Convert to new model if data is still a string result
            jsonParsed = {
                token: jsonParsed,
                firebaseProjectId: null
            };
        }
        NativeReactor.dispatchEvent('native.push.newPushToken', jsonParsed);
    },
    getToken: function (data) {
        var jsonParsed = NativeBridgeCallback.parseJSON(data);
        if (_.isString(jsonParsed)) {
            // Convert to new model if data is still a string result
            jsonParsed = {
                token: jsonParsed,
                firebaseProjectId: null
            };
        }
        NativeReactor.dispatchEvent('native.push.getToken', jsonParsed);
    },
    messageRecieved: function (data) {
        data = NativeBridgeCallback.parseJSON(data);
        NativeReactor.dispatchEvent('native.push.messageRecieved', data);
    }
};

// Scanner
NativeReactor.registerEvent('native.scanner.scanBarcode');

NativeBridgeCallback.scanner = {
    scanBarcode: function (data) {
        data = NativeBridgeCallback.parseJSON(data);
        NativeReactor.dispatchEvent('native.scanner.scanBarcode', data);
    }
};

// Keyboard
NativeReactor.registerEvent('native.keyboard.onWillHide');
NativeReactor.registerEvent('native.keyboard.onDidHide');
NativeReactor.registerEvent('native.keyboard.onWillShow');
NativeReactor.registerEvent('native.keyboard.onDidShow');

NativeBridgeCallback.keyboard = {
    onWillHide: function () {
        NativeReactor.dispatchEvent('native.keyboard.onWillHide', null);
    },
    onDidHide: function () {
        NativeReactor.dispatchEvent('native.keyboard.onDidHide', null);
    },
    onWillShow: function () {
        NativeReactor.dispatchEvent('native.keyboard.onWillShow', null);
    },
    onDidShow: function () {
        NativeReactor.dispatchEvent('native.keyboard.onDidShow', null);
    }
};

// Sharing
NativeReactor.registerEvent('native.sharing.shareOnFacebook');

NativeBridgeCallback.sharing = {
    shareOnFacebook: function (data) {
        data = NativeBridgeCallback.parseJSON(data);
        NativeReactor.dispatchEvent('native.sharing.shareOnFacebook', data);
    }
};

window.NativeBridgeCallback = NativeBridgeCallback;
window.NativeReactor = NativeReactor;