﻿(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('lessonEvaluation', {
            controllerAs: 'ctrl',
            template: require('./LessonEvaluationComponent.html'),
            controller: ['$timeout', LessonEvaluationController],
            bindings: {
                lesson: '<',
                isLastLesson: '<',
                onFinishEvaluation: '<',
                onGoToQuestion: '<',
            }
        })

    function LessonEvaluationController($timeout) {
        const ctrl = this,
            passedImages = [
                '/Content/Graphics/Training/clapperboard.png',
                '/Content/Graphics/Training/nerd_face.png',
                '/Content/Graphics/Training/star_face.png',
            ],
            failedImages = [
                '/Content/Graphics/Training/grinning_face.png',
                '/Content/Graphics/Training/convenience_face.png',
                '/Content/Graphics/Training/grimacing_face.png',
            ],
            timedImage = '/Content/Graphics/Training/Stopwatch.png',
            randomImgIndex = Math.floor(Math.random() * 3);

        ctrl.$onInit = init;
        ctrl.$onDestroy = destroy;

        function init() {
            $timeout(() => {
                document.querySelector('lesson-evaluation.hidden').classList.remove('hidden');
            }, 300);

            ctrl.totalQuestionsCount = ctrl.lesson.QuestionList?.length;
            ctrl.rightQuestionsCount = ctrl.lesson.QuestionList.filter(question => question.IsCorrect)?.length;
            ctrl.isTimedOut = ctrl.totalQuestionsCount && ctrl.lesson.QuestionList.find(question => question.AutoCreatedAfterTimeout && !question.IsCorrect);
            ctrl.titleImage = ctrl.isTimedOut ? timedImage : (ctrl.lesson.Passed ? passedImages[randomImgIndex] : failedImages[randomImgIndex]);
        }

        function destroy() {
            document.querySelector('lesson-evaluation').classList.add('hidden');
        }
    }
})();