﻿(() => {
    'use strict';

    angular
        .module('App.Training')
        .component('badgeTestimonialsPopup', {
            template: require('./BadgeTestimonialsPopupComponent.html'),
            controllerAs: 'ctrl',
            controller: ['$element', 'LessonPopupService', BadgeTestimonialsPopupController],
            bindings: {
                onSubmit: '<',
                data: '<'
            }
        });

    function BadgeTestimonialsPopupController($element, LessonPopupService) {
        const ctrl = this;

        ctrl.testimonial = {};
        ctrl.showTestimonials = !ctrl.data.NewUserCourseBadges.length && ctrl.data.GiveTestimonial;
        ctrl.showBadges = true;

        ctrl.submit = submit;

        function submit() {
            if (!ctrl.hideBadges && ctrl.data.NewUserCourseBadges.length) {
                ctrl.hideBadges = true;
            }
            if (ctrl.data.GiveTestimonial) {
                ctrl.showTestimonials = true;
                if (!ctrl.testimonial.submitted) {
                    ctrl.testimonial.CourseToken = ctrl.data.CourseToken;
                    LessonPopupService.submitTestimonial(ctrl.testimonial)
                        .then(function (testimonial) {
                            ctrl.testimonial = testimonial;
                        });
                } else {
                    ctrl.onSubmit();
                    ctrl.close();
                }
            } else {
                ctrl.onSubmit()
                ctrl.close();
            }
        }
    }
})();